import { FC, useId, useState } from "react"

import { Document, Endorsement, PolicySource } from "@appia/api"
import { Link } from "@appia/ui-components"
import * as RD from "@appia/remote-data"
import { useGetEndorsementDocuments } from "src/swr"
import { downloadFile } from "src/utils/download"
import { compareByFileExtension } from "src/utils/documents"

import Loading from "src/components/Loading"
import ErrorMessage from "src/components/ErrorMessage"
import DownloadWarningModal from "src/components/DownloadWarningModal"
import { DocumentContent, DocumentTabs, MessageWrapper } from "./TabLayout"

const ViewEndorsementMessage: FC<{
  platformUrl: string | null
  source: PolicySource | null
}> = ({ platformUrl, source }) => (
  <MessageWrapper>
    <p className="font-bold">
      This email didn&rsquo;t contain the endorsement.
    </p>

    {platformUrl && source === "whitespace" && (
      <>
        <p className="mb-4">
          Please use the button below to view the file in Whitespace.
        </p>

        <Link
          theme="night"
          style="outlined"
          label="View in Whitespace"
          href={platformUrl}
          target="_blank"
        />
      </>
    )}
  </MessageWrapper>
)

export const EndorsementDocumentPanel: FC<{
  documents: Document[]
  platformUrl: string | null
  source: PolicySource | null
  setupDownloadClick?: (document: Document) => () => void
}> = ({ documents, platformUrl, source, setupDownloadClick }) => {
  const viewEndorsementTabId = useId()

  const viewEndorsementTabTrigger = {
    value: viewEndorsementTabId,
    label: "View email info",
  }

  const sortedDocuments = [...documents].sort(compareByFileExtension)

  const documentTabTriggers = sortedDocuments.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  const viewEndorsementTabPanel = {
    value: viewEndorsementTabId,
    content: (
      <ViewEndorsementMessage platformUrl={platformUrl} source={source} />
    ),
  }

  const documentTabPanels = sortedDocuments.map(document => ({
    value: document.id,
    content: (
      <DocumentContent
        document={document}
        handleDownloadClick={setupDownloadClick?.(document)}
      />
    ),
  }))

  const hasDocuments = sortedDocuments.length > 0

  // We want to display the 'View endorsement' tab if the endorsement email
  // didn't contain a PDF attachment. Since the original .eml file comes through
  // as a PDF for rendering purposes, we therefore need to check whether we have
  // more than one PDF
  const hasPdfAttachment =
    sortedDocuments.filter(doc => !doc.isSpreadsheet).length > 1

  // If we don't have any documents, or we do but none of them are a PDF
  // attachment, then we show the special 'View endorsement' tab
  const showViewEndorsementTab = !hasDocuments || !hasPdfAttachment

  const [activeDocumentId, setActiveDocumentId] = useState<Document["id"]>(() =>
    sortedDocuments.length > 0 ? sortedDocuments[0].id : viewEndorsementTabId,
  )

  return (
    <DocumentTabs
      title="Endorsement documents"
      activeTabId={activeDocumentId}
      setActiveTabId={setActiveDocumentId}
      tabTriggers={
        showViewEndorsementTab
          ? [viewEndorsementTabTrigger, ...documentTabTriggers]
          : documentTabTriggers
      }
      tabPanels={
        showViewEndorsementTab
          ? [viewEndorsementTabPanel, ...documentTabPanels]
          : documentTabPanels
      }
    />
  )
}

const EndorsementDocuments: FC<{
  endorsement: Endorsement
}> = ({ endorsement }) => {
  const { request: documentsRequest } = useGetEndorsementDocuments(
    endorsement.id,
  )

  const [isWarningModalOpen, setWarningModalOpen] = useState(false)
  const [documentToDownload, setDocumentToDownload] = useState<Document>()

  const isShowEndorsementTypeWarning = endorsement.type === "request"

  const downloadDocument = ({ url, name, mimetype }: Document): void => {
    downloadFile(url, name, mimetype)
  }

  const handleDownloadClick = (document: Document) => (): void => {
    if (isShowEndorsementTypeWarning) {
      showWarningModal(document)
    } else {
      downloadDocument(document)
    }
  }

  const showWarningModal = (document: Document): void => {
    setDocumentToDownload(document)
    setWarningModalOpen(true)
  }

  const confirmDownload = (): void => {
    if (!documentToDownload) return

    downloadDocument(documentToDownload)
    setWarningModalOpen(false)
  }

  return RD.match(
    documentsRequest,
    <Loading className="mt-8" />,
    <Loading className="mt-8" />,
    documents => (
      <>
        <EndorsementDocumentPanel
          documents={documents}
          platformUrl={endorsement.platformUrl}
          source={endorsement.policyData?.source ?? null}
          setupDownloadClick={handleDownloadClick}
        />
        <DownloadWarningModal
          isOpen={isWarningModalOpen}
          onClose={() => setWarningModalOpen(false)}
          onConfirm={confirmDownload}
          title="This is not the stamped endorsement"
          message="Note that this file is not the stamped endorsement, and so should not be sent to partners."
        />
      </>
    ),

    error => <ErrorMessage message="Something went wrong" error={error} />,
  )
}

export default EndorsementDocuments
