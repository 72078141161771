import { FC } from "react"
import { Button, Callout, ModalDescription } from "@appia/ui-components"

import ModalTemplate from "src/components/ModalTemplate"

interface DownloadWarningModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  message: string
}

const DownloadWarningModal: FC<DownloadWarningModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
}) => (
  <ModalTemplate
    isOpen={isOpen}
    onClose={onClose}
    title={title}
    content={
      <ModalDescription as="div" className="align-start gap-4">
        <Callout type="warning">
          <p className="mx-1">{message}</p>
        </Callout>
      </ModalDescription>
    }
    actions={[
      <Button
        key="cancel"
        label="Cancel"
        theme="night"
        style="outlined"
        onClick={onClose}
      />,
      <Button
        data-testid="download-confirm-btn"
        key="download"
        label="Download"
        theme="pop"
        style="filled"
        onClick={onConfirm}
      />,
    ]}
  />
)

export default DownloadWarningModal
