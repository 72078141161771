import { AxiosInstance, AxiosPromise } from "axios"
import {
  KiJourneyType,
  MajorClassCode,
  MinorClassCode,
  SubClassCode,
} from "@appia/api"
import type { User } from "../Users"
import { KiRiskType } from "../PBQA"
import { EnergyGroupClassCode } from "./EnergyGroupClass"
import { ContractFileSource, ContractFileType } from "../Files"

export interface ContractRequest {
  pbqaIds: string[]
}

export interface MinorClasses {
  [key: string]: string[]
}

export interface MinorSubClassResponse {
  minor_classes: MinorClasses
}

export interface MinorSubClassesRequest {
  inceptionYear: number
  groupClass: string
  producingTeam: string
  classType: string
  majorClass: string
}

export const getMinorAndSubClassesUrl = (
  data: MinorSubClassesRequest,
): string => {
  const url = "/api/classes-of-business/minor-and-sub-classes"

  const params = new URLSearchParams({
    inception_year: data.inceptionYear.toString(),
    group_class: data.groupClass,
    producing_team: data.producingTeam,
    class_type: data.classType,
    major_class: data.majorClass,
  })

  return `${url}?${params}`
}

export const getContractDetailsUrl = (contractId: Contract["id"]): string =>
  `/api/contracts/${contractId}/details`

export const getContractUrl = (contractId: Contract["id"]): string =>
  `/api/contracts/${contractId}`

export const useGetContractByVersionIdUrl = (
  contractId: Contract["id"],
  versionId: number,
): string => `/api/contracts/${contractId}/version/${versionId}`

export const archiveContract = (
  client: AxiosInstance,
  contractId: string,
): AxiosPromise<void> => client.put(`/api/contracts/${contractId}/archive`)

export const unarchiveContract = (
  client: AxiosInstance,
  contractId: string,
): AxiosPromise<void> => client.put(`/api/contracts/${contractId}/unarchive`)

export const assignContract = (
  client: AxiosInstance,
  contractId: string,
  versionId: number,
  assignedTo: string,
): AxiosPromise<void> =>
  client.put(
    `/api/contracts/${contractId}/version/${versionId}/${assignedTo}/assign`,
  )

export const unassignContract = (
  client: AxiosInstance,
  contractId: string,
  versionId: number,
): AxiosPromise<void> =>
  client.put(`/api/contracts/${contractId}/version/${versionId}/unassign`)

export const searchContractsUrl = (params: URLSearchParams): string =>
  `/api/contracts/search?${params.toString()}`

export const getContractVersionsUrl = (contractId: Contract["id"]): string =>
  `/api/contracts/${contractId}/versions`

export interface ContractInsuredName {
  name: string
}

export type ContractStatus =
  | "created"
  | "completed"
  | "archived"
  | "in_progress"

export interface Contract {
  id: string
  pbqaIds: string[]
  version: number
}

export const createContract = (
  client: AxiosInstance,
  data: ContractRequest,
): AxiosPromise<ContractResponse> => {
  return client.post(`/api/contracts`, data)
}

export const getContractByVersionId = (
  client: AxiosInstance,
  contractId: Contract["id"],
  versionId: number,
): AxiosPromise<ContractResponse> => {
  return client.get(`/api/contracts/${contractId}/version/${versionId}`)
}

export interface UpdateHeaderData {
  conductRating: ConductRating
  customerType: CustomerType
  brokerName: string
}

export const updateContractHeader = (
  client: AxiosInstance,
  data: UpdateHeaderData,
  contractId: string,
  version: number,
): AxiosPromise<UpdateHeaderData> => {
  return client.put(
    `/api/contracts/${contractId}/version/${version}/header`,
    data,
  )
}

export const completeContract = (
  client: AxiosInstance,
  contractId: string,
  version: number,
  completedBy: string,
): AxiosPromise<ContractResponse> => {
  return client.put(
    `/api/contracts/${contractId}/version/${version}/complete/${completedBy}`,
  )
}

export const updateContractSections = (
  client: AxiosInstance,
  data: ContractSectionItem[],
  contractId: string,
  version: number,
): AxiosPromise<ContractSectionItem[]> => {
  return client.put(
    `/api/contracts/${contractId}/version/${version}/sections`,
    data,
  )
}

export interface ContractsPipelineSearch {
  items: ContractsPipelineSearchResult[]
  hits: number
}

export interface ContractsPipelineSearchResult {
  id: string
  latestVersionId: number
  externalId: string
  insured?: string
  brokingHouse?: string
  uniqueMarketReference?: string
  assignedTo?: string
  inceptionDate: string
  aggregateTotalPremium: number
  aggregateClassesOfBusiness: string[]
  versionStatus: ContractStatus
  archived: boolean
  archivedAt: string | null
}

export const CUSTOMER_TYPE_INDIVIDUAL = "Individual"
export const CUSTOMER_TYPE_MICRO_ENTERPRISE = "Micro Enterprise"
export const CUSTOMER_TYPE_SMALL_CORPORATE = "Small Corporate"
export const CUSTOMER_TYPE_MED_OR_LARGE_CORPORATE = "Medium or Large corporate"
export const CUSTOMER_TYPE_INSURANCE_CARRIER = "Insurance Carrier"

export type CustomerType =
  | typeof CUSTOMER_TYPE_INDIVIDUAL
  | typeof CUSTOMER_TYPE_MICRO_ENTERPRISE
  | typeof CUSTOMER_TYPE_SMALL_CORPORATE
  | typeof CUSTOMER_TYPE_MED_OR_LARGE_CORPORATE
  | typeof CUSTOMER_TYPE_INSURANCE_CARRIER

export const CONDUCT_RATING_HIGH = "High Conduct Risk"
export const CONDUCT_RATING_MEDIUM = "Medium Conduct Risk"
export const CONDUCT_RATING_LOW = "Low Conduct Risk"

export type ConductRating =
  | typeof CONDUCT_RATING_HIGH
  | typeof CONDUCT_RATING_MEDIUM
  | typeof CONDUCT_RATING_LOW

export interface ContractsSearchQuery {
  // Search parameters
  insured: string[] | null
  versionStatus: ContractStatus[] | null
  uniqueMarketReference: string[] | null
  brokingHouse: string[] | null
  assignedTo: User["id"][] | null
  archived: boolean
  unassigned: boolean[] | null
  pageNumber: number
  pageSize: number
}

export interface ContractHeaderItem {
  aggregateClassOfBusiness: string[]
  aggregateWrittenPremium: number
  brokerName: string
  brokeragePercentage: number
  brokingHouse: string
  conductRating: ConductRating
  contractHeaderId: number
  createdAt: Date
  customerType: CustomerType
  domicileCountry: string
  expiryDate: Date
  inceptionDate: Date
  insured: string
  otherCommissions: number | null
  updatedAt: Date
  versionId: number
}

export interface ReferenceSplit {
  britPolicyReference: string | null
  eea: boolean | null
  policyReference: string
  premiumAllocation: number | null
  premiumAllocationCurrency: string | null
  premiumAllocationPercent: number | null
  riskCodes: string[] | null
  ggTechnicalPrice: number | null
  ggModelPrice: number | null
  gnModelPlr: number | null
  gnTechnicalPlr: number | null
}

export interface EnergyReferenceSplit extends ReferenceSplit {
  limitAllocation: number | null
  limitAllocationCurrency: string | null
  limitAllocationPercent: number | null
  minorClass: MinorClassCode
  subClass: SubClassCode | null
  groupClass: EnergyGroupClassCode
}

export interface KiSyndicateData {
  groupClass: string | null
  subClass: SubClassCode | null
  kiJourney: KiJourneyType
  britProgrammeReference: string | null
  minorClass: MinorClassCode
  majorClass: MajorClassCode
  producingTeam: string | null
  classType: string | null
  referenceSplit: Array<ReferenceSplit | EnergyReferenceSplit> | null
  businessType: KiRiskType
  type: string
}

export interface SyndicateData {
  syndicateDataId: number
  lineId: number
  type: string
  syndicateData: KiSyndicateData
  updatedAt: Date
}

export interface SyndicateLine {
  lineId: number
  contractId: string
  sectionId: number
  nominatedSyndicateId: number
  nominatedSyndicateShare: number | null
  lineSizeAmount: number | null
  lineSizePercentage: number | null
  lbs: string | null
  writtenPremium: number | null
  writtenPremiumCurrency: string | null
  updatedAt: Date
  eea: boolean | null
  modelPrice: number | null
  technicalPrice: number | null
  syndicateData: SyndicateData
}

export interface RARCItem {
  rarcId: number
  sectionId: number
  changeInLad: number
  changeInCoverage: number
  changeInOther: number
  uncappedRARC: number
  rarc: number
  isDefaulted: boolean
  changeInSocialInflation: number
  changeInExposureInflation: number
  changeInExcessInflation: number
  changeInEconomicInflation: number
}

export interface ContractSectionItem {
  sectionId: number
  journeyConfigId: number | null
  contractHeaderId: number
  limitAmount: number
  limitAmountCurrency: string
  excess: number
  aggregateRiskData: string
  cyberExposure: string
  cyberWarExposure: string
  deductible: number
  yoa: number
  updatedAt: Date
  riskCodes: string[]
  classOfBusiness: string
  quoteLineId: string
  pbqaId: string
  lines: SyndicateLine[]
  brokerSettledIndicator: string | null
  brokerSelectedIndustry: string | null
  selectedSubClass?: string
  quoteSource?: number
  rarc: RARCItem | null
  renewalContract: boolean | null
}

export interface ContractVersionItem {
  versionId: number
  versionStatus: ContractStatus
  contractId: string
  assignedTo: string | null
  createdAt: Date
  completedAt: Date | null
  quoteIds: number[]
  externalId: string
}

export interface ContractItem {
  contractId: string
  archived: boolean
  uniqueMarketReference: string
  version: ContractVersionItem
  archivedAt: Date | null
}

export interface ContractResponse {
  contract: ContractItem
  header: ContractHeaderItem
  sections: ContractSectionItem[]
  files?: ContractFile[] | null
}

export interface ContractVersion {
  versionId: number
  contractId: string
  createdAt: string
  completedAt: Date | null
}

export interface ContractDetails {
  versions: ContractVersion[]
  contract: ContractResponse
}

export interface ContractFile {
  id: string
  name: string
  type: keyof typeof ContractFileType
  source: keyof typeof ContractFileSource
  status: string
  uploadedToPartnerIngestionDate: boolean
  updatedAt: Date
}
