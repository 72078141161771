import {
  FC,
  ReactElement,
  ReactNode,
  Ref,
  createContext,
  useContext,
} from "react"

import ReactSelect, {
  DropdownIndicatorProps,
  GroupBase,
  IndicatorSeparatorProps,
  InputProps,
  MenuProps,
  MultiValueGenericProps,
  OptionProps,
  PlaceholderProps,
  Props,
  StylesConfig,
  ThemeConfig,
  components,
} from "react-select"
import ReactSelectAsync, { AsyncProps } from "react-select/async"
import type SelectType from "react-select/base"

import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "./Icons"
import classNames from "classnames"
import { ottoGrey, ottoPop, ottoRed } from "@appia/ui-palette"
import { KiDivision } from "../../api/src/Endorsements"

interface SelectContextType {
  required?: boolean
  truncateItems?: boolean
}

const SelectContext = createContext<SelectContextType>({})

export interface SelectOption {
  value: string
  label: string
}

interface SelectBaseProps<T> {
  onSelect: (s: T) => void
  placeholder: string

  "aria-labelledby"?: string
  defaultMenuIsOpen?: boolean
  disabled?: boolean
  errorMessageId?: string
  id?: string
  onFocus?: () => void
  required?: boolean
  truncateItems?: boolean
}

export type SelectRefType = SelectType<SelectOption, false>

export interface SelectProps extends SelectBaseProps<string> {
  options: SelectOption[]
  selectedValue: string | null

  inputRef?: Ref<SelectRefType>
}

export const SelectWithDivisions: FC<SelectProps> = ({
  onSelect,
  options,
  placeholder,
  "aria-labelledby": ariaLabelledBy,
  defaultMenuIsOpen = false,
  disabled = false,
  errorMessageId,
  id,
  inputRef,
  onFocus,
  required = false,
  selectedValue,
  truncateItems,
}) => {
  const selectedOption = options.find(opt => opt.value === selectedValue) ?? []

  const styles: StylesConfig<SelectOption> = mkStylesOverrides()

  const props: Props<SelectOption, false> = {
    "aria-errormessage": errorMessageId,
    "aria-invalid": !!errorMessageId,
    "aria-labelledby": ariaLabelledBy,
    components: {
      DropdownIndicator,
      IndicatorSeparator,
      Input,
      Menu,
      Option: OptionGroupClass,
      Placeholder,
    },
    defaultMenuIsOpen,
    inputId: id,
    isDisabled: disabled,
    menuPlacement: "auto",
    closeMenuOnSelect: false,
    onChange: opt => {
      if (opt !== null) {
        onSelect(opt.value)
      }
    },
    onFocus,
    options,
    placeholder,
    styles,
    theme: themeOverrides,
    value: selectedOption,
  }

  return (
    <SelectContext.Provider value={{ truncateItems, required }}>
      <ReactSelect {...props} ref={inputRef} />
    </SelectContext.Provider>
  )
}

const OptionGroupClass = <T, Multi extends boolean>({
  children,
  ...props
}: OptionProps<T, Multi>): ReactElement => {
  const { truncateItems } = useContext(SelectContext)

  return (
    <components.Option {...props}>
      <SelectOptionInnerGroupClass
        selected={props.isSelected}
        active={props.isFocused}
        truncateItems={truncateItems}
      >
        {children}
      </SelectOptionInnerGroupClass>
    </components.Option>
  )
}

const SelectOptionInnerGroupClass: FC<{
  active: boolean
  label?: string
  selected: boolean
  truncateItems?: boolean
  children?: ReactNode
}> = ({ active, children, label, selected, truncateItems = true }) => {
  const kiDivisions = Object.keys(KiDivision)

  const styling = {
    division:
      "font-bold relative cursor-pointer select-none py-2 pl-3 pr-9 text-base text-otto-grey-900",
    groupClass:
      "indent-3 relative cursor-pointer select-none py-2 pl-3 pr-9 text-base text-otto-grey-900",
  }

  const isDivision = (children: ReactNode): boolean => {
    return typeof children === "string" && kiDivisions.includes(children)
  }

  return (
    <div
      title={label || (typeof children === "string" ? children : undefined)}
      data-testid="select-option-inner"
      className={classNames(
        isDivision(children) || children === "All"
          ? styling.division
          : styling.groupClass,
        { "bg-otto-pop-100 forced-colors:bg-SystemHighlight": active },
      )}
      style={isDivision(children) ? { borderTop: "1px solid #E5E7EB" } : {}}
    >
      <span
        className={classNames(
          isDivision(children) ? "font-bold" : "font-normal",
          "block",
          {
            truncate: truncateItems,
            "whitespace-nowrap": !truncateItems,
          },
        )}
      >
        {label || children}
      </span>

      {selected ? (
        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
          <div className="h-5 w-5">
            <CheckIcon />
          </div>
        </span>
      ) : null}
    </div>
  )
}

export const Select: FC<SelectProps> = ({
  onSelect,
  options,
  placeholder,

  "aria-labelledby": ariaLabelledBy,
  defaultMenuIsOpen = false,
  disabled = false,
  errorMessageId,
  id,
  inputRef,
  onFocus,
  required = false,
  selectedValue,
  truncateItems,
}) => {
  const selectedOption =
    options.find(opt => opt.value === selectedValue) ?? null

  const styles: StylesConfig<SelectOption> = mkStylesOverrides()

  const props: Props<SelectOption, false> = {
    "aria-errormessage": errorMessageId,
    "aria-invalid": !!errorMessageId,
    "aria-labelledby": ariaLabelledBy,
    components: {
      DropdownIndicator,
      IndicatorSeparator,
      Input,
      Menu,
      Option,
      Placeholder,
    },
    defaultMenuIsOpen,
    inputId: id,
    isDisabled: disabled,
    menuPlacement: "auto",
    onChange: opt => {
      if (opt !== null) {
        onSelect(opt.value)
      }
    },
    onFocus,
    options,
    placeholder,
    styles,
    theme: themeOverrides,
    value: selectedOption,
  }

  return (
    <SelectContext.Provider value={{ truncateItems, required }}>
      <ReactSelect {...props} ref={inputRef} />
    </SelectContext.Provider>
  )
}

export type SelectMultipleRefType = SelectType<SelectOption, true>

export interface SelectMultipleProps extends SelectBaseProps<string[]> {
  options: SelectOption[]
  selectedValues: string[]

  inputRef?: Ref<SelectMultipleRefType>
}

export const SelectMultiple: FC<SelectMultipleProps> = ({
  onSelect,
  options,
  placeholder,

  "aria-labelledby": ariaLabelledBy,
  defaultMenuIsOpen = false,
  disabled = false,
  errorMessageId,
  id,
  inputRef,
  onFocus,
  required = false,
  selectedValues = [],
  truncateItems,
}) => {
  const selectedOptions = options.filter(opt =>
    selectedValues.includes(opt.value),
  )

  const styles: StylesConfig<SelectOption> = mkStylesOverrides()

  const props: Props<SelectOption, true> = {
    "aria-errormessage": errorMessageId,
    "aria-invalid": !!errorMessageId,
    "aria-labelledby": ariaLabelledBy,
    components: {
      DropdownIndicator,
      IndicatorSeparator,
      Input,
      Menu,
      MultiValueLabel,
      Option,
      Placeholder,
    },
    defaultMenuIsOpen,
    inputId: id,
    isDisabled: disabled,
    isMulti: true,
    menuPlacement: "auto",
    onChange: opts => onSelect(opts.map(o => o.value)),
    onFocus,
    options,
    placeholder,
    styles,
    theme: themeOverrides,
    value: selectedOptions,
  }

  return (
    <SelectContext.Provider value={{ required, truncateItems }}>
      <ReactSelect {...props} ref={inputRef} />
    </SelectContext.Provider>
  )
}

export type SelectAsyncRefType<T> = SelectType<T, false, GroupBase<T>>

export interface SelectAsyncProps<T> extends SelectBaseProps<T> {
  loadResults: (searchQuery: string) => Promise<T[]>
  mapResultToLabel: (t: T) => string
  mapResultToValue: (t: T) => string
  selectedValue: T | null

  defaultInputValue?: string | undefined
  inputRef?: Ref<SelectAsyncRefType<T>>
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const SelectAsync = <T extends unknown>({
  loadResults,
  mapResultToLabel,
  mapResultToValue,
  onSelect,
  placeholder,

  "aria-labelledby": ariaLabelledBy,
  defaultMenuIsOpen = false,
  disabled = false,
  errorMessageId,
  id,
  inputRef,
  onFocus,
  required = false,
  selectedValue,
  truncateItems,
  defaultInputValue = undefined,
}: SelectAsyncProps<T>): ReactElement => {
  const styles: StylesConfig<T> = mkStylesOverrides()

  const props: AsyncProps<T, false, GroupBase<T>> = {
    "aria-errormessage": errorMessageId,
    "aria-invalid": !!errorMessageId,
    "aria-labelledby": ariaLabelledBy,
    components: {
      DropdownIndicator,
      IndicatorSeparator,
      Input,
      Menu,
      Option,
      Placeholder,
    },
    cacheOptions: true,
    defaultMenuIsOpen,
    defaultOptions: true,
    inputId: id,
    isDisabled: disabled,
    isMulti: false,
    getOptionLabel: mapResultToLabel,
    getOptionValue: mapResultToValue,
    loadOptions: loadResults,
    menuPlacement: "auto",
    noOptionsMessage: () => "No options - type to search",
    onChange: opt => {
      if (opt !== null) {
        onSelect(opt)
      }
    },
    onFocus,
    placeholder,
    styles,
    theme: themeOverrides,
    value: selectedValue,
    defaultInputValue,
  }

  return (
    <SelectContext.Provider value={{ required, truncateItems }}>
      <ReactSelectAsync {...props} ref={inputRef} />
    </SelectContext.Provider>
  )
}

const ottoPopMain = ottoPop[700]
const ottoBrightRed = ottoRed[600]

// TODO Get these from TW config
const ottoTextBase = "0.875rem"
const ottoHeight8 = "2rem"

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const mkStylesOverrides = <T extends unknown>(): StylesConfig<T> => ({
  clearIndicator: provided => ({
    ...provided,
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
  }),
  container: provided => ({
    ...provided,
    fontSize: ottoTextBase,
  }),
  control: (provided, state) => {
    const hasError = !!state.selectProps["aria-errormessage"]

    return {
      ...provided,
      backgroundColor: state.isDisabled
        ? ottoGrey[200]
        : provided.backgroundColor,
      borderColor: hasError ? ottoBrightRed : ottoGrey[400],
      boxShadow: "none",
      minHeight: ottoHeight8,
      "&:hover": {
        // Intentionally don't include the provided hover styles
        cursor: "pointer",
      },
      "&:focus-within": {
        borderColor: hasError ? ottoBrightRed : ottoPopMain,
        // These outline styles match the `.otto-focus-inset` mixin
        outline: `${
          hasError ? ottoBrightRed : ottoPopMain
        } solid 2px !important`,
        outlineOffset: "-2px",
      },
    }
  },
  dropdownIndicator: provided => ({
    ...provided,
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
  }),
  option: provided => ({
    ...provided,
    backgroundColor: "transparent",
    padding: 0,
  }),
  placeholder: provided => ({
    ...provided,
    // This should have sufficient contrast even when the input is disabled
    color: ottoGrey[700],
  }),
  multiValue: provided => ({
    ...provided,
    // This roughly translates to a 20 character limit
    maxWidth: "10rem",
  }),
  singleValue: (provided, state) => {
    const hasError = !!state.selectProps["aria-errormessage"]
    const { isDisabled } = state.selectProps

    return {
      ...provided,
      color: isDisabled
        ? ottoGrey[900]
        : hasError
        ? ottoRed[900]
        : provided.color,
    }
  },
  valueContainer: provided => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
})

const themeOverrides: ThemeConfig = theme => ({
  ...theme,
  colors: { ...theme.colors, primary: ottoPopMain },
})

const DropdownIndicator = <T, Multi extends boolean>(
  props: DropdownIndicatorProps<T, Multi>,
): ReactElement => {
  const classes = "w-5 text-otto-grey-600 self-center"
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <ChevronUpIcon className={classes} />
      ) : (
        <ChevronDownIcon className={classes} />
      )}
    </components.DropdownIndicator>
  )
}

const IndicatorSeparator = <T, Multi extends boolean>(
  props: IndicatorSeparatorProps<T, Multi>,
): ReactElement | null => {
  return props.isMulti && !props.isDisabled && props.hasValue ? (
    <components.IndicatorSeparator {...props} />
  ) : null
}

const Input = <T, Multi extends boolean>(
  props: InputProps<T, Multi>,
): ReactElement => {
  const { required } = useContext(SelectContext)
  return (
    <components.Input
      {...props}
      required={required}
      data-cy="async-select-input"
    />
  )
}

const Option = <T, Multi extends boolean>({
  children,
  ...props
}: OptionProps<T, Multi>): ReactElement => {
  const { truncateItems } = useContext(SelectContext)

  return (
    <components.Option {...props}>
      <SelectOptionInner
        selected={props.isSelected}
        active={props.isFocused}
        truncateItems={truncateItems}
      >
        {children}
      </SelectOptionInner>
    </components.Option>
  )
}

const Placeholder = <T, Multi extends boolean>(
  props: PlaceholderProps<T, Multi>,
): ReactElement => <components.Placeholder className="truncate" {...props} />

const Menu = <T, Multi extends boolean>({
  children,
  ...props
}: MenuProps<T, Multi>): ReactElement => {
  const { truncateItems } = useContext(SelectContext)
  return (
    <components.Menu
      {...props}
      className={classNames(props.className, "!z-20 forced-colors:border", {
        // If the menu items are not truncated, allow this component to grow
        // to fit them
        "!w-max min-w-full": truncateItems === false,
      })}
    >
      {children}
    </components.Menu>
  )
}

const MultiValueLabel: FC<MultiValueGenericProps<SelectOption, true>> = ({
  children,
  ...props
}) => (
  <components.MultiValueLabel {...props}>
    <span title={props.data.label}>{children}</span>
  </components.MultiValueLabel>
)

export const SelectOptionInner: FC<{
  active: boolean
  label?: string
  selected: boolean
  truncateItems?: boolean
  children?: ReactNode
}> = ({ active, children, label, selected, truncateItems = true }) => (
  <div
    title={label || (typeof children === "string" ? children : undefined)}
    data-testid="select-option-inner"
    className={classNames(
      "relative cursor-pointer select-none py-2 pl-3 pr-9 text-base text-otto-grey-900",
      { "bg-otto-pop-100 forced-colors:bg-SystemHighlight": active },
    )}
  >
    <span
      className={classNames(selected ? "font-bold" : "font-normal", "block", {
        truncate: truncateItems,
        "whitespace-nowrap": !truncateItems,
      })}
    >
      {label || children}
    </span>

    {selected ? (
      <span className="absolute inset-y-0 right-0 flex items-center pr-3">
        <div className="h-5 w-5">
          <CheckIcon />
        </div>
      </span>
    ) : null}
  </div>
)
