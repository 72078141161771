export const getRenewalDisplayValue = (
  renewalContract: boolean | null,
): string => {
  if (renewalContract === null) {
    return "null"
  }
  return renewalContract ? "renewal" : "newBusiness"
}

export const RENEWAL_OPTIONS = [
  { label: "New Business", value: "newBusiness" },
  { label: "Renewal", value: "renewal" },
]
