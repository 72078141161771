import { useEffect } from "react"
import { useGetCurrentUser } from "../swr"
import * as RD from "@appia/remote-data"
import { useLDClient } from "launchdarkly-react-client-sdk"

export const useLaunchDarkly = (): void => {
  const { request: userRequest } = useGetCurrentUser()

  const currentUser = RD.isSuccess(userRequest) ? userRequest.data.user : null
  const ldClient = useLDClient()

  useEffect(() => {
    if (currentUser && ldClient) {
      ldClient.identify({
        key: currentUser.email ?? "anonymous",
        email: currentUser.email,
        firstName: currentUser.forename,
        lastName: currentUser.surname,
      })
    }
  }, [ldClient, currentUser])
}

export const UserInitializer = (): null => {
  useLaunchDarkly()
  return null
}
