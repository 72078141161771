import {
  FormField,
  ValidatedData,
  mkInvalidData,
  mkValidData,
} from "@appia/form-data"
import { currencyOptions } from "./mockData"
import { MinorClassCode, SubClassCode } from "@appia/api"

const INVALID_FIELD_ERROR_MESSAGE = "Please fill in this field."
const INVALID_CURRENCY_FIELD_ERROR_MESSAGE = "Please select a currency."
const INVALID_TOTAL_PREMIUM_ERROR_MESSAGE =
  "Please enter an amount that adds up to 100% total written premium across all the sections."

const COMPARISON_TOLERANCE = 0.1

type CurrencyValue =
  | "USD"
  | "GBP"
  | "EUR"
  | "AED"
  | "AFN"
  | "ALL"
  | "AMD"
  | "ANG"
  | "AOA"
  | "ARS"
  | "AUD"
  | "AWG"
  | "AZN"
  | "BAM"
  | "BBD"
  | "BDT"
  | "BGN"
  | "BHD"
  | "BIF"
  | "BMD"
  | "BND"
  | "BOB"
  | "BOV"
  | "BRL"
  | "BSD"
  | "BTN"
  | "BWP"
  | "BYN"
  | "BZD"
  | "CAD"
  | "CDF"
  | "CHE"
  | "CHF"
  | "CHW"
  | "CLF"
  | "CLP"
  | "CNY"
  | "COP"
  | "COU"
  | "CRC"
  | "CUC"
  | "CUP"
  | "CVE"
  | "CZK"
  | "DJF"
  | "DKK"
  | "DOP"
  | "DZD"
  | "EGP"
  | "ERN"
  | "ETB"
  | "FJD"
  | "FKP"
  | "GEL"
  | "GHS"
  | "GIP"
  | "GMD"
  | "GNF"
  | "GTQ"
  | "GYD"
  | "HKD"
  | "HNL"
  | "HRK"
  | "HTG"
  | "HUF"
  | "IDR"
  | "ILS"
  | "INR"
  | "IQD"
  | "IRR"
  | "ISK"
  | "JMD"
  | "JOD"
  | "JPY"
  | "KES"
  | "KGS"
  | "KHR"
  | "KMF"
  | "KPW"
  | "KRW"
  | "KWD"
  | "KYD"
  | "KZT"
  | "LAK"
  | "LBP"
  | "LKR"
  | "LRD"
  | "LSL"
  | "LYD"
  | "MAD"
  | "MDL"
  | "MGA"
  | "MKD"
  | "MMK"
  | "MNT"
  | "MOP"
  | "MRU"
  | "MUR"
  | "MVR"
  | "MWK"
  | "MXN"
  | "MXV"
  | "MYR"
  | "MZN"
  | "NAD"
  | "NGN"
  | "NIO"
  | "NOK"
  | "NPR"
  | "NZD"
  | "OMR"
  | "PAB"
  | "PEN"
  | "PGK"
  | "PHP"
  | "PKR"
  | "PLN"
  | "PYG"
  | "QAR"
  | "RON"
  | "RSD"
  | "RUB"
  | "RWF"
  | "SAR"
  | "SBD"
  | "SCR"
  | "SDG"
  | "SEK"
  | "SGD"
  | "SHP"
  | "SLE"
  | "SLL"
  | "SOS"
  | "SRD"
  | "SSP"
  | "STN"
  | "SVC"
  | "SYP"
  | "SZL"
  | "THB"
  | "TJS"
  | "TMT"
  | "TND"
  | "TOP"
  | "TRY"
  | "TTD"
  | "TWD"
  | "TZS"
  | "UAH"
  | "UGX"
  | "USN"
  | "UYI"
  | "UYU"
  | "UYW"
  | "UZS"
  | "VED"
  | "VES"
  | "VND"
  | "VUV"
  | "WST"
  | "XAF"
  | "XAG"
  | "XAU"
  | "XBA"
  | "XBB"
  | "XBC"
  | "XBD"
  | "XCD"
  | "XDR"
  | "XOF"
  | "XPD"
  | "XPF"
  | "XPT"
  | "XSU"
  | "XTS"
  | "XUA"
  | "XXX"
  | "YER"
  | "ZAR"
  | "ZMW"
  | "ZWL"

export type ValueAndCurrency = {
  amount: number | null
  unit: string | null
}

export type ValidatedValueAndCurrency = {
  amount: number
  unit: CurrencyValue
}

const parsePolicyReference = (value: string): ValidatedData<string> => {
  if (value.replace(/\s+/g, "").length === 0 || value.includes(" ")) {
    return mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)
  }

  return mkValidData(value)
}

const parseEeaOrRow = (value: boolean | null): ValidatedData<boolean> =>
  value !== null
    ? mkValidData(value)
    : mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)

const isSectionCurrency = (value: unknown): value is CurrencyValue =>
  typeof value === "string" &&
  !!currencyOptions.find(elem => elem.label === value)

const isSectionsPremiumEqualToLayerPremium = (
  sectionsTotal: number,
  layerPremium: number,
): boolean => {
  return Math.abs(sectionsTotal - layerPremium) > COMPARISON_TOLERANCE
}

const parseSectionCurrency = (
  value: string | null,
): ValidatedData<CurrencyValue> =>
  isSectionCurrency(value)
    ? mkValidData(value)
    : mkInvalidData(INVALID_CURRENCY_FIELD_ERROR_MESSAGE)

export const parseSectionValue = (
  value: number | null,
): ValidatedData<number> =>
  value != null && value > 0
    ? mkValidData(value)
    : mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)

const parseSectionValueAndCurrency = (
  input: ValueAndCurrency | null,
  sectionsTotal?: number,
  layerPremium?: number,
): ValidatedData<ValidatedValueAndCurrency> => {
  if (input == null) {
    return mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)
  }
  const parsedValue = parseSectionValue(input.amount)
  const parsedCurrency = parseSectionCurrency(input.unit)

  if (
    parsedValue.valid &&
    parsedCurrency.valid &&
    sectionsTotal &&
    layerPremium
  ) {
    const failedTotalPremiumCheck = isSectionsPremiumEqualToLayerPremium(
      sectionsTotal,
      layerPremium,
    )
    if (failedTotalPremiumCheck) {
      return mkInvalidData(INVALID_TOTAL_PREMIUM_ERROR_MESSAGE)
    }
  }

  if (parsedValue.valid && parsedCurrency.valid) {
    return mkValidData({ amount: parsedValue.data, unit: parsedCurrency.data })
  } else if (!parsedValue.valid) {
    return mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)
  } else if (!parsedCurrency.valid) {
    return mkInvalidData(INVALID_CURRENCY_FIELD_ERROR_MESSAGE)
  } else {
    return mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)
  }
}

const parseSectionPercentage = (
  value: number | null,
  sectionsTotal?: number,
  layerPremium?: number,
): ValidatedData<number> => {
  if (value != null && value > 0) {
    if (sectionsTotal && layerPremium) {
      const failedTotalPremiumCheck = isSectionsPremiumEqualToLayerPremium(
        sectionsTotal,
        layerPremium,
      )
      if (failedTotalPremiumCheck) {
        return mkInvalidData("")
      }
    }
    return mkValidData(value)
  } else {
    return mkInvalidData("")
  }
}

const isMinorClass = (value: unknown): value is MinorClassCode =>
  typeof value === "string" &&
  Object.values(MinorClassCode).includes(value as MinorClassCode)

const parseMinorClass = (value: string | null): ValidatedData<MinorClassCode> =>
  isMinorClass(value)
    ? mkValidData(value as MinorClassCode)
    : mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)

const energyGroupClassValues = [
  "energy_operational",
  "energy_construction",
  "energy_gom_wind",
  "energy_liability",
  "energy_midstream",
  "energy_renewable",
] as const

type GroupClass = (typeof energyGroupClassValues)[number]

const isGroupClass = (value: unknown): value is GroupClass =>
  typeof value === "string" &&
  energyGroupClassValues.includes(value as GroupClass)

const parseGroupClass = (value: string | null): ValidatedData<GroupClass> =>
  isGroupClass(value)
    ? mkValidData(value)
    : mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)

const isSubClass = (value: unknown): value is SubClassCode =>
  typeof value === "string" &&
  Object.values(SubClassCode).includes(value as SubClassCode)

const parseSubClass = (value: string | null): ValidatedData<SubClassCode> =>
  isSubClass(value)
    ? mkValidData(value as SubClassCode)
    : mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)

const parseRenewalContract = (value: boolean | null): ValidatedData<boolean> =>
  value === null
    ? mkInvalidData(INVALID_FIELD_ERROR_MESSAGE)
    : mkValidData(value)

export const calculatePremiumAllocationPercentage = (
  money: FormField<ValidatedValueAndCurrency, ValueAndCurrency>,
  layerWrittenPremium: number | null,
): number | null => {
  // Truthy check on layerWrittenPremium will prevent division by 0, since 0 is falsey.
  return money.validated.valid && layerWrittenPremium
    ? (money.validated.data.amount * 100) / layerWrittenPremium
    : null
}

export const calculatePremiumAllocationAmount = (
  premiumAllocationPercent: FormField<number, number | null>,
  layerWrittenPremium: number | null,
): number | null => {
  return premiumAllocationPercent.validated.valid &&
    layerWrittenPremium !== null
    ? (layerWrittenPremium * premiumAllocationPercent.validated.data) / 100
    : null
}

export {
  COMPARISON_TOLERANCE,
  isSectionsPremiumEqualToLayerPremium,
  parsePolicyReference,
  parseEeaOrRow,
  parseSectionPercentage,
  parseSectionValueAndCurrency,
  parseMinorClass,
  parseSubClass,
  parseGroupClass,
  parseRenewalContract,
}
