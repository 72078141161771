import { AxiosInstance, AxiosPromise } from "axios"
import { Broker } from "../Broker"
import { Overlining } from "./Overlining"
import { Syndicate } from "../Syndicate"

/**
 * Surveys
 */
export type KiJourneyType =
  | "nominated_lead"
  | "brit_follow_renewal"
  | "brit_new_business"
  | "nominated_follow"
  | "no_nominated_syndicated"

export interface PBQASurveyDetails {
  id: string
  slug: string
  active: boolean
  kiJourneyTypes: KiJourneyType[]
}

export interface PBQASurvey {
  id: string
  slug: string
  version: number
  groupingHeaders: string[]
  questions: PBQAQuestion[]
  answers: PBQAAnswer[]
}

/**
 * Questions
 */

export interface PBQABoundingBox {
  documentId: string
  pageNumber: number
  x0: number
  x1: number
  y0: number
  y1: number
}

interface BaseQuestion<T> {
  canOverride: boolean
  defaultAccepted: boolean | null
  grouping: number
  id: string
  isSyndicateSpecific: boolean
  label: string
  layout: "input" | "question" | "comparison"
  required: boolean
  text: string
  type: T
}

export type PBQABooleanQuestion = Omit<BaseQuestion<"boolean">, "label">
export type PBQABrokerQuestion = BaseQuestion<"broker">
export type PBQADateQuestion = BaseQuestion<"date">
export type PBQAInceptionDateQuestion = BaseQuestion<"inception_date">
export type PBQAInsuredQuestion = BaseQuestion<"insured">
export type PBQAStringQuestion = BaseQuestion<"string">
export type PBQASyndicateQuestion = BaseQuestion<"syndicate">
export type PBQAUmrQuestion = BaseQuestion<"umr">
export type PBQAPolicyReferenceQuestion = BaseQuestion<"policy_reference">
export type PBQAOverliningQuestion = BaseQuestion<"overlining">
export interface PBQAEEAQuestion extends BaseQuestion<"eea"> {
  acceptableUnits: string[]
}

export interface PBQADecimalQuestion extends BaseQuestion<"decimal"> {
  acceptableUnits: string[]
  precision: number | undefined
}

export interface PBQAIntegerQuestion extends BaseQuestion<"integer"> {
  acceptableUnits: string[]
}

export interface PBQACountryQuestion extends BaseQuestion<"country"> {
  choices: string[]
}

export interface PBQAOptionQuestion extends BaseQuestion<"option"> {
  choices: string[]
}

export interface PBQAOptionMultiQuestion extends BaseQuestion<"option_multi"> {
  choices: string[]
}

export type PBQAQuestion =
  | PBQABooleanQuestion
  | PBQABrokerQuestion
  | PBQACountryQuestion
  | PBQADateQuestion
  | PBQADecimalQuestion
  | PBQAInceptionDateQuestion
  | PBQAInsuredQuestion
  | PBQAIntegerQuestion
  | PBQAOptionMultiQuestion
  | PBQAOptionQuestion
  | PBQAStringQuestion
  | PBQASyndicateQuestion
  | PBQAUmrQuestion
  | PBQAPolicyReferenceQuestion
  | PBQAOverliningQuestion
  | PBQAEEAQuestion

/**
 * Answers
 */
interface BaseAnswer<T> {
  accepted: boolean | null
  answer: T | null
  boundingBox: PBQABoundingBox | null
  note: string | null
  pbqaId: string
  questionId: string
  reasons: PBQAAnswerReason[] | null
  syndicateId: Syndicate["id"] | null
}

export type PBQAAnswerReason =
  | "no_nominated_syndicate"
  | "ki_line_less_quote"
  | "leader_line_higher"
  | "leader_line_lower"
  | "ai_quote"
  | "premium_higher"
  | "premium_netted_down"
  | "premium_pro_rata"
  | "premium_within_5_threshold"
  | "premium_over_5_threshold"
  | "ki_one_of_two_lloyds_syndicates"
  | "limit_lower_than_quoted"
  | "limit_over"
  | "ai_conversion_to_usd"
  | "no_surplus_lines_info"
  | "brokerage_lower"
  | "brokerage_higher"
  | "nominated_syndicate_for_qbe_facility"
  | "not_applicable"
  | "other"
  | "country_of_insured_changed"
  | "ki_line_high_quote"
  | "minimum_deposit_premium"
  | "other_deductions_lower"
  | "inception_date_changed"
  | "expiry_date_changed"
  | "slip_risk_code"
  | "no_service_of_suit"
  | "sov_not_required"
  | "nominated_follow_unable_to_confirm_line_size"
  | "confirmation_of_knorl"

export type PBQABooleanAnswer = BaseAnswer<boolean>
export type PBQABrokerAnswer = BaseAnswer<Broker>
export type PBQACountryAnswer = BaseAnswer<string>
export type PBQADateAnswer = BaseAnswer<string>
export type PBQAInceptionDateAnswer = BaseAnswer<string>
export type PBQAInsuredAnswer = BaseAnswer<string>
export type PBQAOptionAnswer = BaseAnswer<string>
export type PBQAOptionMultiAnswer = BaseAnswer<string[]>
export type PBQAStringAnswer = BaseAnswer<string>
export type PBQASyndicateAnswer = BaseAnswer<Syndicate>
export type PBQAUmrAnswer = BaseAnswer<string>
export type PBQAPolicyReferenceAnswer = BaseAnswer<string>
export type PBQAOverliningAnswer = BaseAnswer<Overlining>

export interface PBQADecimalAnswer extends BaseAnswer<number> {
  unit: string | null
}

export interface PBQAIntegerAnswer extends BaseAnswer<number> {
  unit: string | null
}

export interface PBQAEEAAnswer extends BaseAnswer<number> {
  unit: string | null
}

export type PBQAAnswer =
  | PBQABooleanAnswer
  | PBQABrokerAnswer
  | PBQACountryAnswer
  | PBQADateAnswer
  | PBQADecimalAnswer
  | PBQAInceptionDateAnswer
  | PBQAInsuredAnswer
  | PBQAIntegerAnswer
  | PBQAOptionAnswer
  | PBQAOptionMultiAnswer
  | PBQAStringAnswer
  | PBQASyndicateAnswer
  | PBQAUmrAnswer
  | PBQAPolicyReferenceAnswer
  | PBQAOverliningAnswer
  | PBQAEEAAnswer

/**
 * API methods
 */
export const getPBQASurveysAvailableUrl = (pbqaId: string): string =>
  `/api/pbqa/${pbqaId}/surveys/available`

export const getPBQASurveysAvailable = (
  client: AxiosInstance,
  pbqaId: string,
): AxiosPromise<PBQASurveyDetails[]> =>
  client.get(getPBQASurveysAvailableUrl(pbqaId))

export const getPBQASurveyUrl = (pbqaId: string, surveyId: string): string =>
  `/api/pbqa/${pbqaId}/surveys/${surveyId}`

export const getPBQASurvey = (
  client: AxiosInstance,
  pbqaId: string,
  surveyId: string,
): AxiosPromise<PBQASurvey> => client.get(getPBQASurveyUrl(pbqaId, surveyId))

export const savePBQASurvey = (
  client: AxiosInstance,
  pbqaId: string,
  surveyId: string,
  answers: PBQAAnswer[],
): AxiosPromise =>
  client.put(`/api/pbqa/${pbqaId}/surveys/${surveyId}`, answers)

export const confirmPBQASurvey = (
  client: AxiosInstance,
  pbqaId: string,
  surveyId: string,
): AxiosPromise =>
  client.post(`/api/pbqa/${pbqaId}/surveys/${surveyId}/confirm`, {})

export const getKiHeadersheetData = (
  client: AxiosInstance,
  pbqaId: string,
  surveyId: string,
): AxiosPromise<string> =>
  client.get(`/api/pbqa/${pbqaId}/surveys/${surveyId}/ki_export`)
