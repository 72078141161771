type LogTableFilterParams = Record<"field" | "value" | "tableId", string>

export const handleOnSelectGroupClass = (
  value: string,
  filterAllKey: string,
  clearDivisionSelected: (() => void) | undefined,
  filterKey: string,
  tableId: string,
  onValueChange: (v: string | null) => void,
  logTableFilter: (params: LogTableFilterParams) => void,
): void => {
  if (value === filterAllKey && clearDivisionSelected) {
    onValueChange(null)
    clearDivisionSelected()
  } else {
    onValueChange(value)

    if (value) {
      logTableFilter({ field: filterKey, value: value, tableId })
    }
  }
}
