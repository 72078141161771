import {
  FC,
  HTMLAttributes,
  ReactElement,
  ReactNode,
  Ref,
  useEffect,
  useState,
} from "react"

import {
  Button,
  Card,
  SelectorIcon,
  SortAscendingIcon,
  SortDescendingIcon,
} from "@appia/ui-components"

import Pagination from "./Pagination"
import NoResultsFound from "./NoResultsFound"
import {
  ActiveFilter,
  ActiveFilterDivision,
  FilterAsync,
  FilterDefinition,
  FilterSync,
} from "./Filters"
export { configureFilter, useTableFilters } from "./Filters"

import Loading from "src/components/Loading"
import ErrorMessage from "src/components/ErrorMessage"

import classNames from "classnames"

import * as RD from "@appia/remote-data"

import { logButtonClick, logTableSort } from "src/amplitude"
import usePageName from "src/contexts/PageNameContext"
import useApiClient from "src/contexts/ApiClientContext"
import { groupClassToDivisionMapping } from "src/utils/groupClassToDivisionMapping"

const CELL_STYLES = `max-w-xxs p-2 first:pl-4 last:pr-4`

type Direction = "asc" | "desc"

// A column definition
export interface Column<K> {
  columnKey: K
  label: string
  sortable?: boolean
  truncate?: boolean
  visuallyHideLabel?: boolean
}

interface ColumnHeaderProps<K> extends Column<K> {
  activeKey: K
  onSort?: () => void
  sortDirection: Direction
}

const ColumnHeader = <K extends string>({
  activeKey,
  columnKey,
  label,
  onSort,
  sortDirection,
  visuallyHideLabel,
}: ColumnHeaderProps<K>): ReactElement => {
  let ariaSort: HTMLAttributes<HTMLTableHeaderCellElement>["aria-sort"]
  let icon: ReactNode = null

  if (onSort !== undefined) {
    if (activeKey !== columnKey) {
      ariaSort = "none"
      icon = <SelectorIcon />
    } else if (sortDirection === "asc") {
      ariaSort = "ascending"
      icon = <SortAscendingIcon />
    } else {
      ariaSort = "descending"
      icon = <SortDescendingIcon />
    }
  }

  return (
    <th
      className={classNames(
        CELL_STYLES,
        "whitespace-nowrap py-3 text-left text-otto-pop",
      )}
      aria-sort={ariaSort}
      data-cy={`sort-${columnKey}`}
    >
      {!visuallyHideLabel && onSort !== undefined ? (
        <button
          className="otto-focus cursor-pointer rounded text-base font-bold"
          onClick={onSort}
        >
          <span>{label}</span>

          <span className="ml-2 inline-block w-4 align-middle">{icon}</span>
        </button>
      ) : visuallyHideLabel ? (
        <span className="sr-only">{label}</span>
      ) : (
        <span>{label}</span>
      )}
    </th>
  )
}

// Render the table controls (filters and reset button)
export const TableControls: FC<{
  filters: FilterDefinition<unknown>[]
  onClear: () => void
  tableId: string
  tableLabel: string
  divisionSelected?: string
  clearDivisionSelected?: () => void
}> = ({
  filters,
  onClear,
  tableId,
  tableLabel,
  divisionSelected,
  clearDivisionSelected,
}) => {
  const apiClient = useApiClient()
  const pageName = usePageName()
  const controlsHeadingId = `${tableId}-controls`

  const showClearButton = filters.some(f => f.values.length > 0)

  const isGroupClassPartOfSelectedDivision = (
    divisionSelected: string,
    value: string,
  ): boolean => {
    return (
      groupClassToDivisionMapping[divisionSelected]?.includes(value) || false
    )
  }

  return (
    <section
      aria-labelledby={controlsHeadingId}
      className="mb-4 grid w-full grow-0 gap-4"
    >
      <h2
        id={controlsHeadingId}
        className="sr-only"
      >{`${tableLabel} controls`}</h2>

      <div className="grid w-full grid-cols-1 items-center gap-2 sm:grid-cols-2 md:auto-cols-fr md:grid-flow-col">
        {filters.map(filter =>
          filter.type === "sync" ? (
            <FilterSync
              key={filter.uniqueKey}
              tableId={tableId}
              filterKey={filter.uniqueKey}
              onValueChange={v => {
                if (v === null) {
                  return filter.onChange([])
                }

                const existingValues = new Set(filter.values)
                if (!existingValues.has(v)) {
                  return filter.onChange([...filter.values, v])
                }
              }}
              options={filter.options}
              placeholder={filter.label}
              selectedValues={filter.values}
              clearDivisionSelected={clearDivisionSelected}
            />
          ) : (
            <FilterAsync
              key={filter.uniqueKey}
              tableId={tableId}
              filterKey={filter.uniqueKey}
              onValueChange={v => {
                // We need to stringify before comparing for equality because
                // the values may be objects
                const existingValues = new Set(
                  filter.values.map(v => JSON.stringify(v)),
                )

                if (!existingValues.has(JSON.stringify(v))) {
                  return filter.onChange([...filter.values, v])
                }
              }}
              loadResults={async searchQuery => {
                const { data } = await filter.loader(apiClient, searchQuery)
                return data
              }}
              mapResultToLabel={filter.mapResultToLabel}
              mapResultToValue={filter.mapResultToValue}
              placeholder={filter.label}
            />
          ),
        )}
      </div>

      <div>
        <ul className="flex flex-wrap gap-2">
          {divisionSelected && clearDivisionSelected && (
            <ActiveFilterDivision
              division={divisionSelected}
              onClear={clearDivisionSelected}
            />
          )}

          {filters.flatMap(
            ({ values, mapResultToLabel, uniqueKey, label, onChange }) =>
              values.map(value => {
                if (
                  divisionSelected &&
                  uniqueKey === "groupClass" &&
                  isGroupClassPartOfSelectedDivision(
                    divisionSelected,
                    value as string,
                  )
                ) {
                  return null
                }
                return (
                  <ActiveFilter
                    key={`${uniqueKey}-${JSON.stringify(value)}`}
                    label={label}
                    value={mapResultToLabel(value)}
                    onClear={() => onChange(values.filter(v => v !== value))}
                  />
                )
              }),
          )}

          {showClearButton && (
            <li>
              <Button
                theme="night"
                style="text"
                size="small"
                label="Clear"
                aria-label={`Clear ${tableLabel} filters`}
                data-cy="clear"
                onClick={() => {
                  onClear()

                  logButtonClick({
                    buttonName: "Clear filters",
                    containerName: tableLabel,
                    pageName,
                  })
                }}
              />
            </li>
          )}
        </ul>
      </div>
    </section>
  )
}

const PaginationContainer: FC<{
  onChangePageNumber: (n: number) => void
  onChangePageSize: (n: number) => void
  pageNumber: number
  pageSize: number
  tableId: string
  tableLabel: string
  totalRows: number
}> = ({
  onChangePageNumber,
  onChangePageSize,
  pageNumber,
  pageSize,
  tableId,
  tableLabel,
  totalRows,
}) => {
  const paginationHeadingId = `${tableId}-pagination`

  return (
    <section
      aria-labelledby={paginationHeadingId}
      className="flex w-full items-center justify-end rounded rounded-t-none border border-t-0 border-otto-grey-400 bg-white p-2 forced-colors:border-t"
    >
      <h2
        id={paginationHeadingId}
        className="sr-only"
      >{`${tableLabel} pagination`}</h2>

      <Pagination
        tableLabel={tableLabel}
        totalHits={totalRows}
        pageNumber={pageNumber}
        setPageNumber={onChangePageNumber}
        pageSize={pageSize}
        setPageSize={onChangePageSize}
      />
    </section>
  )
}

type TableState =
  | { tag: "loading" }
  | { tag: "error"; error: Error }
  | { tag: "loadedNoResults" }
  | { tag: "loadedHasResults" }

// Table state / configuration
export interface TableSettings<Keys> {
  pageNumber: number
  pageSize: number
  sortDirection: Direction
  sortKey: Keys
}

type RowsAndCount<Data> = { rows: Data[]; totalRows: number }

interface TableProps<Data, Keys> {
  columns: Column<Keys>[]
  dataRequest: RD.RemoteData<Error, RowsAndCount<Data>>
  id: string
  label: string
  onSettingsChange: (ts: TableSettings<Keys>) => void
  renderCell: (k: Keys, row: Data) => ReactElement
  settings: TableSettings<Keys>
  tableRef?: Ref<HTMLTableElement>
}

const DashboardTable = <Data extends { id: string }, Keys extends string>({
  columns,
  dataRequest,
  id,
  label,
  onSettingsChange,
  renderCell,
  settings,
  tableRef,
}: TableProps<Data, Keys>): ReactElement => {
  // Cache this so that the pagination doesn't flicker while data is loading
  const [totalRows, setTotalRows] = useState<number>(0)
  useEffect(() => {
    if (RD.isSuccess(dataRequest)) {
      setTotalRows(dataRequest.data.totalRows)
    } else if (RD.isFailure(dataRequest)) {
      setTotalRows(0)
    }
  }, [dataRequest])

  const tableHeadingId = `${id}-table`
  const tableLoadingId = `${id}-loading`
  const tableNoResultsId = `${id}-no-results`

  const tableState = RD.match<Error, RowsAndCount<Data>, TableState>(
    dataRequest,
    { tag: "loading" },
    { tag: "loading" },
    data =>
      data.rows.length === 0
        ? { tag: "loadedNoResults" }
        : { tag: "loadedHasResults" },
    error => ({ tag: "error", error }),
  )

  let tableDescribedBy: string | undefined = undefined
  switch (tableState.tag) {
    case "loading":
      tableDescribedBy = tableLoadingId
      break
    case "loadedNoResults":
      tableDescribedBy = tableNoResultsId
      break
    default:
      break
  }

  const { pageNumber, pageSize, sortKey, sortDirection } = settings

  // Add 1 because aria-rowindex is 1-indexed, then add 1 again to account for
  // the header row
  const startRowIdx = (pageNumber - 1) * pageSize + 2

  return (
    <div className="flex grow flex-col">
      <section
        aria-labelledby={tableHeadingId}
        className="otto-focus-inset relative grow overflow-auto border-x border-otto-grey-400 bg-white"
        // Make the section programmatically focusable so that keyboard users
        // can scroll the table on narrow screens
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      >
        <h2 id={tableHeadingId} className="sr-only">
          {label}
        </h2>

        <table
          // The inclination is to use `role="grid"` on this `<table>` for
          // better accessibility, because the table is sortable / filterable
          // and contains interactive content, but per the heuristics in
          // https://sarahmhigley.com/writing/grids-part1/, the default `table`
          // role actually seems most appropriate
          aria-labelledby={tableHeadingId}
          aria-describedby={tableDescribedBy}
          // Always at least 1 because of the header row
          aria-rowcount={Math.max(1, totalRows)}
          className="w-full bg-white"
          ref={tableRef}
        >
          <thead className="bg-otto-night forced-colors:border-y">
            <tr aria-rowindex={1}>
              {columns.map(col => {
                const onSort = col.sortable
                  ? () => {
                      const direction =
                        settings.sortKey === col.columnKey
                          ? settings.sortDirection === "asc"
                            ? "desc"
                            : "asc"
                          : settings.sortDirection

                      logTableSort({
                        column: col.columnKey,
                        direction,
                        tableId: id,
                      })

                      onSettingsChange({
                        ...settings,
                        sortDirection: direction,
                        sortKey: col.columnKey,
                      })
                    }
                  : undefined

                return (
                  <ColumnHeader
                    key={col.columnKey}
                    activeKey={sortKey}
                    sortDirection={sortDirection}
                    onSort={onSort}
                    {...col}
                  />
                )
              })}
            </tr>
          </thead>

          <tbody>
            {(RD.isSuccess(dataRequest) ? dataRequest.data.rows : []).map(
              (row, i) => {
                return (
                  <tr
                    key={row.id}
                    aria-rowindex={startRowIdx + i}
                    className="border-b border-otto-grey-400"
                    data-cy="search-result"
                  >
                    {columns.map(col => {
                      return (
                        <td
                          key={`${row.id}-${col.columnKey}`}
                          className={classNames(CELL_STYLES, {
                            truncate: col.truncate === true,
                          })}
                        >
                          {renderCell(col.columnKey, row)}
                        </td>
                      )
                    })}
                  </tr>
                )
              },
            )}
          </tbody>
        </table>

        {/* Always render this alert even if its contents are empty so that
            screenreaders have the best chance of picking it up */}
        <section role="alert">
          {tableState.tag === "loadedNoResults" && (
            <div
              className="mx-auto mt-4 py-4 text-center"
              id={tableNoResultsId}
            >
              <NoResultsFound />
              <p className="mt-6 font-bold">
                No results were found for this search.
              </p>
              <p className="mt-2">
                Try adjusting your search or filter to find what you’re looking
                for.
              </p>
            </div>
          )}
        </section>

        {tableState.tag === "loading" && (
          <Loading id={tableLoadingId} className="mt-4" />
        )}

        {tableState.tag === "error" && (
          <Card className="mx-auto mt-4 w-fit">
            <ErrorMessage
              error={tableState.error}
              message="Failed to load data"
            />
          </Card>
        )}
      </section>

      <hr
        // If the PaginationContainer and table aren't touching, each should
        // have a border. However, we don't want a double border when they do
        // touch. To solve that problem, this <hr> functions as a border but it
        // will shrink away when the other elements grow large enough.
        className={classNames(
          "mt-auto max-h-[1px] grow border-0 bg-otto-grey-400",
          // Only allow the <hr> to shrink away if there are results; keep the
          // border for all other states
          tableState.tag === "loadedHasResults"
            ? "shrink basis-0"
            : "shrink-0 basis-[1px]",
        )}
      />

      <PaginationContainer
        onChangePageNumber={pn =>
          onSettingsChange({ ...settings, pageNumber: pn })
        }
        onChangePageSize={ps =>
          onSettingsChange({ ...settings, pageSize: ps, pageNumber: 1 })
        }
        pageNumber={pageNumber}
        pageSize={pageSize}
        tableId={id}
        tableLabel={label}
        totalRows={totalRows}
      />
    </div>
  )
}

export default DashboardTable
