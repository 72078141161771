import { FC, SetStateAction } from "react"
import CollapsibleSectionPanel from "./CollapsibleSectionPanel"
import { EnergySectionFormField, LayerFormField } from "./utils/types"
import {
  ValueAndCurrency,
  calculatePremiumAllocationAmount,
  calculatePremiumAllocationPercentage,
  parseEeaOrRow,
  parseMinorClass,
  parsePolicyReference,
  parseRenewalContract,
  parseSectionPercentage,
  parseSectionValueAndCurrency,
  parseSubClass,
} from "./parsers"
import ContractSectionsEnergyPanel from "./ContractSectionsEnergyPanel"
import { mkValidData } from "@appia/form-data"
import { MAJOR_CLASS_CODE_DESCRIPTION_MAPPING } from "@appia/api"
import InputWithError from "../../../components/InputWithError"
import {
  RENEWAL_OPTIONS,
  getRenewalDisplayValue,
} from "./utils/toggleRenewalUtils"
import { ToggleRadioButtons } from "@appia/ui-components"

interface ContractSectionEnergyLayerProps {
  openLayer: LayerFormField
  setLayerFormFields: (
    setStateFn: SetStateAction<Record<string, LayerFormField>>,
  ) => void
}

const ContractSectionEnergyLayer: FC<ContractSectionEnergyLayerProps> = ({
  openLayer,
  setLayerFormFields,
}) => {
  interface AllocationFields {
    premiumAllocationValueAndCurrency?: EnergySectionFormField["premiumAllocationValueAndCurrency"]
    premiumAllocationPercent?: EnergySectionFormField["premiumAllocationPercent"]
    limitAllocationValueAndCurrency?: EnergySectionFormField["limitAllocationValueAndCurrency"]
    limitAllocationPercent?: EnergySectionFormField["limitAllocationPercent"]
  }

  const handleRenewalFieldChange = (value: string): void => {
    const selectedValue = value == "renewal"
    setLayerFormFields(prevState => ({
      ...prevState,
      [openLayer.layerId]: {
        ...prevState[openLayer.layerId],
        renewalContract: {
          raw: selectedValue,
          validated: parseRenewalContract(selectedValue),
          showError: false,
        },
      },
    }))
  }

  const updateAllocationFields = (
    value: ValueAndCurrency,
    referenceValue: number | null,
    type: "premium" | "limit",
  ): AllocationFields => {
    const allocationValueAndCurrency = {
      raw: value,
      validated: parseSectionValueAndCurrency(value),
      showError: false,
    }

    const rawPercentage = calculatePremiumAllocationPercentage(
      allocationValueAndCurrency,
      referenceValue,
    )

    const allocationPercent = {
      raw: rawPercentage,
      validated: parseSectionPercentage(rawPercentage),
      showError: false,
    }

    return type === "premium"
      ? {
          premiumAllocationValueAndCurrency: allocationValueAndCurrency,
          premiumAllocationPercent: allocationPercent,
        }
      : {
          limitAllocationValueAndCurrency: allocationValueAndCurrency,
          limitAllocationPercent: allocationPercent,
        }
  }

  const updateAllocationPercentFields = (
    value: number,
    referenceValue: number | null,
    unit: string | null,
    type: "premium" | "limit",
  ): AllocationFields => {
    const allocationPercent = {
      raw: value,
      validated: parseSectionPercentage(value),
      showError: false,
    }

    const rawAllocationAmount = calculatePremiumAllocationAmount(
      allocationPercent,
      referenceValue,
    )

    const rawAllocationValueAndCurrency = {
      unit,
      amount: rawAllocationAmount,
    }

    const allocationValueAndCurrency = {
      raw: rawAllocationValueAndCurrency,
      validated: parseSectionValueAndCurrency(rawAllocationValueAndCurrency),
      showError: false,
    }

    return type === "premium"
      ? {
          premiumAllocationValueAndCurrency: allocationValueAndCurrency,
          premiumAllocationPercent: allocationPercent,
        }
      : {
          limitAllocationValueAndCurrency: allocationValueAndCurrency,
          limitAllocationPercent: allocationPercent,
        }
  }

  const handleSectionChange = (
    sectionIndex: number,
    field: keyof EnergySectionFormField,
    value: string | number | boolean | ValueAndCurrency | null,
  ): void => {
    setLayerFormFields(prevState => {
      const prevSectionFormFields = prevState[openLayer.layerId].sections[
        sectionIndex
      ] as EnergySectionFormField

      let updatedField
      switch (field) {
        case "premiumAllocationValueAndCurrency": {
          const updatedFields = updateAllocationFields(
            value as ValueAndCurrency,
            prevSectionFormFields.layerWrittenPremium,
            "premium",
          )
          updatedField = {
            premiumAllocationValueAndCurrency:
              updatedFields.premiumAllocationValueAndCurrency,
            premiumAllocationPercent: updatedFields.premiumAllocationPercent,
          }
          break
        }
        case "limitAllocationValueAndCurrency": {
          const updatedFields = updateAllocationFields(
            value as ValueAndCurrency,
            prevSectionFormFields.layerLimit,
            "limit",
          )
          updatedField = {
            limitAllocationValueAndCurrency:
              updatedFields.limitAllocationValueAndCurrency,
            limitAllocationPercent: updatedFields.limitAllocationPercent,
          }
          break
        }
        case "premiumAllocationPercent": {
          const updatedFields = updateAllocationPercentFields(
            value as number,
            prevSectionFormFields.layerWrittenPremium,
            prevSectionFormFields.premiumAllocationValueAndCurrency.raw.unit,
            "premium",
          )
          updatedField = {
            premiumAllocationValueAndCurrency:
              updatedFields.premiumAllocationValueAndCurrency,
            premiumAllocationPercent: updatedFields.premiumAllocationPercent,
          }
          break
        }
        case "limitAllocationPercent": {
          const updatedFields = updateAllocationPercentFields(
            value as number,
            prevSectionFormFields.layerLimit,
            prevSectionFormFields.limitAllocationValueAndCurrency.raw.unit,
            "limit",
          )
          updatedField = {
            limitAllocationValueAndCurrency:
              updatedFields.limitAllocationValueAndCurrency,
            limitAllocationPercent: updatedFields.limitAllocationPercent,
          }
          break
        }
        case "groupClass":
          updatedField = {
            groupClass: {
              raw: value as string,
              validated: mkValidData(value as string),
              showError: !mkValidData(value as string),
            },
          }
          break
        case "minorClass":
          updatedField = {
            minorClass: {
              raw: value as string,
              validated: parseMinorClass(value as string),
              showError: !parseMinorClass(value as string),
            },
          }
          break
        case "subClass":
          updatedField = {
            subClass: {
              raw: value as string,
              validated: parseSubClass(value as string),
              showError: !parseSubClass(value as string),
            },
          }
          break
        default:
          updatedField = {
            [field]: {
              raw: value,
              validated:
                field === "policyRef"
                  ? parsePolicyReference(value as string)
                  : field === "eea"
                  ? parseEeaOrRow(value as boolean)
                  : value,
              showError: false,
            },
          }
      }

      const updatedSectionFormFields = {
        ...prevSectionFormFields,
        ...updatedField,
      }

      return {
        ...prevState,
        [openLayer.layerId]: {
          ...prevState[openLayer.layerId],
          sections: {
            ...prevState[openLayer.layerId].sections,
            [sectionIndex]: updatedSectionFormFields,
          },
        },
      }
    })
  }

  return (
    <>
      <CollapsibleSectionPanel title="Layer Details">
        <section className="flex w-3/4 flex-col gap-2 pl-6 pb-8 pt-4">
          <InputWithError
            errorTestId="renewal-contract-error-message"
            formField={openLayer.renewalContract}
            showFormFieldErrors={openLayer.renewalContract.showError}
            input={() => (
              <ToggleRadioButtons
                legend="New Business or Renewal?"
                options={RENEWAL_OPTIONS}
                selectedValue={getRenewalDisplayValue(
                  openLayer.renewalContract.raw,
                )}
                onSelect={handleRenewalFieldChange}
              />
            )}
          />
        </section>
      </CollapsibleSectionPanel>
      {Object.entries(openLayer.sections).map(([sectionIndex, section]) => {
        const energySection = section as EnergySectionFormField
        return (
          <CollapsibleSectionPanel key={sectionIndex} title="Section">
            <ContractSectionsEnergyPanel
              policyReference={energySection.policyRef}
              onChangePolicyReference={(value: string) => {
                handleSectionChange(Number(sectionIndex), "policyRef", value)
              }}
              eeaOrRow={energySection.eea}
              onChangeEeaOrRow={(value: boolean | null) => {
                handleSectionChange(Number(sectionIndex), "eea", value)
              }}
              premiumAllocationValueAndCurrency={
                energySection.premiumAllocationValueAndCurrency
              }
              onChangePremiumAllocationValueAndCurrency={(
                value: ValueAndCurrency,
              ) => {
                handleSectionChange(
                  Number(sectionIndex),
                  "premiumAllocationValueAndCurrency",
                  value,
                )
              }}
              premiumAllocationPercent={energySection.premiumAllocationPercent}
              onChangePremiumAllocationPercent={(value: number | null) => {
                handleSectionChange(
                  Number(sectionIndex),
                  "premiumAllocationPercent",
                  value,
                )
              }}
              limitAllocationValueAndCurrency={
                energySection.limitAllocationValueAndCurrency
              }
              onChangeLimitAllocationValueAndCurrency={(
                value: ValueAndCurrency,
              ) => {
                handleSectionChange(
                  Number(sectionIndex),
                  "limitAllocationValueAndCurrency",
                  value,
                )
              }}
              limitAllocationPercent={energySection.limitAllocationPercent}
              onChangeLimitAllocationPercent={(value: number | null) => {
                handleSectionChange(
                  Number(sectionIndex),
                  "limitAllocationPercent",
                  value,
                )
              }}
              groupClass={energySection.groupClass}
              onChangeGroupClass={(value: string) => {
                handleSectionChange(Number(sectionIndex), "groupClass", value)
              }}
              minorClass={energySection.minorClass}
              onChangeMinorClass={(value: string) => {
                handleSectionChange(Number(sectionIndex), "minorClass", value)
              }}
              subClass={energySection.subClass}
              onChangeSubClass={(value: string) => {
                handleSectionChange(Number(sectionIndex), "subClass", value)
              }}
              brokerIndustry={openLayer.brokerSelectedIndustry || "N/A"}
              classesRequest={{
                inceptionYear: openLayer.yoa,
                groupClass: energySection.groupClass.raw
                  ? energySection.groupClass.raw
                  : openLayer.groupClass,
                producingTeam: openLayer.producingTeam,
                classType: openLayer.classType,
                majorClass:
                  MAJOR_CLASS_CODE_DESCRIPTION_MAPPING[openLayer.majorClass],
              }}
              selectedSubClass={openLayer.selectedSubClass}
              quoteSource={openLayer.quoteSource}
            />
          </CollapsibleSectionPanel>
        )
      })}
    </>
  )
}

export default ContractSectionEnergyLayer
