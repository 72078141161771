import {
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  forwardRef,
  useMemo,
  useState,
} from "react"

import {
  ENDORSEMENT_GROUP_CLASS_OPTIONS,
  EndorsementAssuredName,
  EndorsementStatus,
  EndorsementType,
  EndorsementUmr,
  EndorsementsSearchResult,
  KiClassOfBusiness,
  KiDivision,
  archiveEndorsement,
  assignEndorsement,
  kiDivisionOptions,
  kiQuoteGroupClassLabels,
  searchEndorsementAssuredNames,
  searchEndorsementUmrs,
  unassignEndorsement,
} from "@appia/api"
import { useGetUsers, useSearchEndorsements } from "src/swr"
import * as Sentry from "@sentry/react"
import * as RD from "@appia/remote-data"

import { logButtonClick } from "src/amplitude"
import usePageName from "src/contexts/PageNameContext"

import {
  ModalOverlay,
  SelectOption,
  SpinnerIcon,
  Toast,
} from "@appia/ui-components"
import StatusPill, { endorsementStatusLabels } from "./StatusPill"
import {
  ENDORSEMENT_TYPE_OPTIONS,
  endorsementTypeLabels,
} from "./endorsementType"

import BaseTable, {
  TableControls as BaseTableControls,
  TableSettings as BaseTableSettings,
  Column,
  configureFilter,
} from "src/components/DashboardTable"
import OwnerSelection from "src/components/OwnerSelection"
import EmptyData from "src/components/EmptyData"

import ActionCTA from "./ActionCTA"
import ActionMenu from "./ActionMenu"
import TitledSpan from "./TitledSpan"

import useApiClient from "src/contexts/ApiClientContext"

import {
  prettyPrintDateString,
  prettyPrintKiJourneyType,
} from "src/utils/prettyPrinters"
import { compareByName, fullNameOrEmail } from "src/utils/users"
import { groupClassToDivisionMapping } from "src/utils/groupClassToDivisionMapping"
import useLocalStorageState from "src/hooks/useLocalStorageState"

export const localStorageFiltersKey = "@otto/endorsements-table-filters"
export const localStorageSettingsKey = "@otto/endorsements-table-settings"

/*
 * ====================
 * Table config
 */
type ColumnKeys =
  | "assured_name"
  | "broker"
  | "umr"
  | "owned_by"
  | "type"
  | "submission_date"
  | "status"
  | "action"
  | "group_class"
  | "journey"

// Configure the columns to render
const tableColumns: Column<ColumnKeys>[] = [
  {
    columnKey: "assured_name",
    label: "Assured name",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "broker",
    label: "Broker",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "umr",
    label: "UMR",
    truncate: true,
    sortable: true,
  },
  {
    columnKey: "owned_by",
    label: "Owner",
    sortable: true,
  },
  {
    columnKey: "type",
    label: "Type",
    sortable: true,
  },
  {
    columnKey: "group_class",
    label: "Group class",
    sortable: true,
  },
  {
    columnKey: "journey",
    label: "Journey",
    sortable: true,
  },
  {
    columnKey: "submission_date",
    label: "Upload date",
    sortable: true,
  },
  {
    columnKey: "status",
    label: "Status",
  },
  {
    columnKey: "action",
    label: "Action",
    visuallyHideLabel: true,
  },
]

// Configure the table's pagination, sorting, etc
export type TableSettings = BaseTableSettings<ColumnKeys>

export const defaultTableSettings: TableSettings = {
  pageNumber: 1,
  pageSize: 20,
  sortDirection: "desc",
  sortKey: "submission_date",
}

// The possible filters that may be active for the table
export interface TableFilters {
  assuredName: EndorsementAssuredName[]
  endorsementType: EndorsementType[]
  ownerId: string[]
  status: EndorsementStatus[]
  umr: EndorsementUmr[]
  groupClass: KiClassOfBusiness[]
}

export const defaultTableFilters: TableFilters = {
  assuredName: [],
  endorsementType: [],
  ownerId: [],
  status: [],
  umr: [],
  groupClass: [],
}

/*
 * ====================
 * Table rendering
 */

// Render an individual table cell for the given column key and Endorsement
const RenderCell: FC<{
  colKey: ColumnKeys
  endorsement: EndorsementsSearchResult
  setLoadingOverlay: (text: string | null) => void
  triggerToast: (type: Toast.ToastType, msg: ReactNode) => void
  updateEndorsements: () => void
}> = ({
  colKey,
  endorsement,
  setLoadingOverlay,
  triggerToast,
  updateEndorsements,
}): ReactElement => {
  const apiClient = useApiClient()
  const pageName = usePageName()

  // Run an action which:
  //  - Shows a loading overlay if it doesn't resolve within a certain timeout
  //  - Shows a toast on success/failure
  //  - Refreshes the endorsements when it succeeds
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  const doAction = async <T extends unknown>(
    action: () => Promise<T>,
    loadingMsg: string,
    successMsg: (t: T) => ReactNode,
    failureMsg: string,
  ): Promise<void> => {
    const timeoutId = setTimeout(() => {
      setLoadingOverlay(loadingMsg)
    }, 300)

    try {
      const res = await action()
      updateEndorsements()
      triggerToast("success", successMsg(res))
    } catch (e) {
      if (e instanceof Error) {
        Sentry.captureException(e)
        triggerToast("error", failureMsg)
      }
    } finally {
      clearTimeout(timeoutId)
      setLoadingOverlay(null)
    }
  }

  switch (colKey) {
    case "assured_name":
      return endorsement.assuredName ? (
        <TitledSpan title={endorsement.assuredName} />
      ) : (
        <EmptyData />
      )
    case "broker":
      return endorsement.brokerName ? (
        <TitledSpan title={endorsement.brokerName} />
      ) : (
        <EmptyData />
      )
    case "umr":
      return endorsement.umr ? (
        <TitledSpan title={endorsement.umr} />
      ) : (
        <EmptyData />
      )
    case "type": {
      return endorsement.type ? (
        <TitledSpan title={endorsementTypeLabels[endorsement.type]} />
      ) : (
        <EmptyData />
      )
    }
    case "group_class": {
      return endorsement.groupClass ? (
        <TitledSpan title={kiQuoteGroupClassLabels[endorsement.groupClass]} />
      ) : (
        <EmptyData />
      )
    }
    case "journey": {
      return endorsement.journey ? (
        <TitledSpan title={prettyPrintKiJourneyType(endorsement.journey)} />
      ) : (
        <EmptyData />
      )
    }
    case "owned_by": {
      const buttonLabel = endorsement.assuredName
        ? `Change endorsement owner for ${endorsement.assuredName}`
        : `Change endorsement owner`
      return (
        <OwnerSelection
          buttonLabel={buttonLabel}
          ownerId={endorsement.ownerId}
          onAssign={async (assigneeId, assigneeName) =>
            doAction(
              () => assignEndorsement(apiClient, endorsement.id, assigneeId),
              "Assigning endorsement",
              () => `Successfully assigned endorsement to ${assigneeName}`,
              "Failed to assign endorsement",
            )
          }
          onUnassign={async () =>
            doAction(
              () => unassignEndorsement(apiClient, endorsement.id),
              "Removing endorsement owner",
              () => "Successfully removed endorsement owner",
              "Failed to remove endorsement owner",
            )
          }
        />
      )
    }
    case "submission_date":
      return <span>{prettyPrintDateString(endorsement.createdAt)}</span>
    case "status":
      return <StatusPill status={endorsement.status} />
    case "action": {
      return (
        <div className="flex items-center justify-between gap-1">
          <div className="w-full">
            <ActionCTA endorsement={endorsement} tableLabel={TABLE_LABEL} />
          </div>
          <ActionMenu
            endorsement={endorsement}
            onArchive={() => {
              logButtonClick({
                buttonName: "Archive",
                containerName: "Endorsement action menu",
                pageName,
              })

              doAction(
                () => archiveEndorsement(apiClient, endorsement.id),
                "Archiving endorsement",
                () => "Successfully archived endorsement",
                "Failed to archive endorsement",
              )
            }}
          />
        </div>
      )
    }
  }
}

/*
 * ====================
 * Constants
 */
const TABLE_LABEL = "Endorsements table"
const TABLE_ID = "endorsements-table-active"

// N.B. This deliberately does not include "archived" since endorsements with
// that status are shown via the ArchiveTable instead
const ENDORSEMENT_STATUSES: EndorsementStatus[] = [
  "created",
  "processing",
  "failed",
  "review_required",
  "review_in_progress",
  "blocked_awaiting_third_party",
  "referred_to_decider",
  "accepted_by_decider",
  "rejected_by_decider",
  "review_complete",
]

const ENDORSEMENT_STATUS_OPTIONS: SelectOption[] = ENDORSEMENT_STATUSES.map(
  status => ({
    value: status,
    label: endorsementStatusLabels[status],
  }),
).sort((a, b) => (a.label > b.label ? 1 : -1)) // Sorts the labels in alphabetical order

export const Table = forwardRef<
  HTMLTableElement,
  {
    tableFilters: TableFilters
    tableSettings: TableSettings
    setTableSettings: Dispatch<SetStateAction<TableSettings>>
    triggerToast: (type: Toast.ToastType, msg: ReactNode) => void
  }
>(({ tableFilters, tableSettings, setTableSettings, triggerToast }, ref) => {
  const { request: endorsementsRequest, update: updateEndorsements } =
    useSearchEndorsements({
      assuredName: tableFilters.assuredName.map(({ name }) => name),
      umr: tableFilters.umr.map(({ value }) => value),
      type: tableFilters.endorsementType,
      order:
        // These cases shouldn't occur if the table UI is configured correctly
        tableSettings.sortKey === "status" || tableSettings.sortKey === "action"
          ? undefined
          : tableSettings.sortKey,
      orderBy: tableSettings.sortDirection,
      ownerId: tableFilters.ownerId,
      page: tableSettings.pageNumber,
      pageSize: tableSettings.pageSize,
      status: tableFilters.status,
      groupClass: tableFilters.groupClass,
    })

  const [loadingOverlay, setLoadingOverlay] = useState<string | null>(null)

  return (
    <>
      <BaseTable
        id={TABLE_ID}
        label={TABLE_LABEL}
        dataRequest={RD.map(
          ({ items, hits }) => ({ rows: items, totalRows: hits }),
          endorsementsRequest,
        )}
        settings={tableSettings}
        onSettingsChange={setTableSettings}
        columns={tableColumns}
        renderCell={(colKey, endorsement) => (
          <RenderCell
            colKey={colKey}
            endorsement={endorsement}
            setLoadingOverlay={setLoadingOverlay}
            triggerToast={triggerToast}
            updateEndorsements={updateEndorsements}
          />
        )}
        tableRef={ref}
      />

      {loadingOverlay && (
        <ModalOverlay
          bgColor="bg-white"
          isOpen
          onClose={() => {
            setLoadingOverlay(null)
          }}
        >
          <div className="isolate inline-block text-center">
            <p className="mb-4 text-5xl font-bold">{loadingOverlay}</p>
            <SpinnerIcon className="mx-auto w-12 text-otto-pop" />
          </div>
        </ModalOverlay>
      )}
    </>
  )
})

export const TableControls: FC<{
  tableFilters: TableFilters
  setTableFilters: Dispatch<SetStateAction<TableFilters>>
  setTableSettings: Dispatch<SetStateAction<TableSettings>>
  division?: string
}> = ({ tableFilters, setTableFilters, setTableSettings, division }) => {
  const { request: usersRequest } = useGetUsers()

  const [divisionSelected, setDivisionSelected] = useLocalStorageState<
    string | null
  >("divisionSelected", division || null)

  const onFilterChange = <K extends keyof TableFilters>(
    key: K,
    val: TableFilters[K],
  ): void => {
    setTableSettings(ts => ({ ...ts, pageNumber: 1 }))
    setTableFilters(tf => ({ ...tf, [key]: val }))
  }

  const ownerOptions = useMemo<SelectOption[]>(() => {
    const users = RD.isSuccess(usersRequest) ? usersRequest.data.users : []

    const unassignedOpt = { value: "unassigned", label: "Unassigned" }

    const usersOpts = users
      .sort(compareByName)
      .map(u => ({ value: u.id, label: fullNameOrEmail(u) }))

    return [unassignedOpt, ...usersOpts]
  }, [usersRequest])

  return (
    <BaseTableControls
      filters={[
        configureFilter<EndorsementAssuredName>({
          type: "async",
          uniqueKey: "assuredName",
          label: "Assured name",
          values: tableFilters.assuredName,
          onChange: vals => onFilterChange("assuredName", vals),
          loader: searchEndorsementAssuredNames,
          mapResultToLabel: ({ name }) => name,
          mapResultToValue: ({ name }) => name,
        }),

        configureFilter<EndorsementType>({
          type: "sync",
          uniqueKey: "endorsementType",
          label: "Endorsement type",
          values: tableFilters.endorsementType,
          onChange: vals => onFilterChange("endorsementType", vals),
          mapResultToLabel: v => endorsementTypeLabels[v],
          options: ENDORSEMENT_TYPE_OPTIONS,
        }),

        configureFilter<EndorsementUmr>({
          type: "async",
          uniqueKey: "umr",
          label: "UMR",
          values: tableFilters.umr,
          onChange: vals => onFilterChange("umr", vals),
          loader: searchEndorsementUmrs,
          mapResultToLabel: ({ value }) => value,
          mapResultToValue: ({ value }) => value,
        }),

        configureFilter({
          type: "sync",
          uniqueKey: "groupClass",
          label: "Group Class",
          values: tableFilters.groupClass,
          onChange: vals => {
            const selectedGroupClass = vals.find(
              val => groupClassToDivisionMapping[val],
            )

            if (selectedGroupClass) {
              setDivisionSelected(selectedGroupClass)
              onFilterChange(
                "groupClass",
                groupClassToDivisionMapping[
                  selectedGroupClass
                ] as KiClassOfBusiness[],
              )
            } else {
              onFilterChange("groupClass", vals as KiClassOfBusiness[])
            }
          },
          options: ENDORSEMENT_GROUP_CLASS_OPTIONS,
          mapResultToLabel: (v: string) => {
            if (kiDivisionOptions[v as KiDivision]) {
              return kiDivisionOptions[v as KiDivision]
            } else {
              return kiQuoteGroupClassLabels[
                v as keyof typeof kiQuoteGroupClassLabels
              ]
            }
          },
        }),

        configureFilter<string>({
          type: "sync",
          uniqueKey: "ownerId",
          label: "Owner",
          values: tableFilters.ownerId,
          onChange: vals => onFilterChange("ownerId", vals),
          mapResultToLabel: v =>
            ownerOptions.find(opt => opt.value === v)?.label ?? "",
          options: ownerOptions,
        }),

        configureFilter<EndorsementStatus>({
          type: "sync",
          uniqueKey: "status",
          label: "Status",
          values: tableFilters.status,
          onChange: vals => onFilterChange("status", vals),
          mapResultToLabel: v => endorsementStatusLabels[v],
          options: ENDORSEMENT_STATUS_OPTIONS,
        }),
      ]}
      onClear={() => {
        setTableSettings(defaultTableSettings)
        setTableFilters(defaultTableFilters)
        setDivisionSelected(null)
      }}
      tableId={TABLE_ID}
      tableLabel={TABLE_LABEL}
      divisionSelected={divisionSelected ?? ""}
      clearDivisionSelected={() => {
        const values = tableFilters.groupClass.filter(
          v =>
            divisionSelected &&
            !groupClassToDivisionMapping[divisionSelected]?.includes(v),
        )

        onFilterChange("groupClass", values)
        setDivisionSelected(null)
      }}
    />
  )
}
