import {
  ReactElement,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react"
import "./index.css"

import { Card } from "@appia/ui-components"
import PDFToolbar, { ZOOM_MAX, ZOOM_MIN } from "./Toolbar"
import Loading from "src/components/Loading"

import { dequal } from "dequal"

import {
  Highlight,
  IHighlight,
  PdfHighlighter,
  PdfLoader,
  Scaled,
} from "react-pdf-highlighter"

import { Document } from "@appia/api"
import classNames from "classnames"

interface Rect {
  x: number
  y: number
  width: number
  height: number
}

export interface BoundingBox {
  documentId: Document["id"]
  pageNumber: number
  rects: Rect[]
}

// These are the dimensions for A4 and must be kept in sync with the API or the
// bounding boxes won't render correctly. See:
// `$MONOREPO/server/app/submissions/repository.py`
const PDF_WIDTH = 2480
const PDF_HEIGHT = 3508

// Pad the bounding box so that it looks better; this is purely aesthetic
const BOUNDING_BOX_PADDING = 10

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const boundingBoxToHighlight = <T extends unknown>({
  boundingBox,
  fieldIdentifier,
}: {
  boundingBox: BoundingBox
  fieldIdentifier: T
}): HighlightWithFieldIdentifier<T> => {
  let minX = 0,
    maxX = 0,
    minY = 0,
    maxY = 0

  const rects: Scaled[] = []
  boundingBox.rects.forEach(rect => {
    const x1 = rect.x - BOUNDING_BOX_PADDING
    if (minX === 0 || x1 < minX) {
      minX = x1
    }

    const x2 = rect.x + rect.width + BOUNDING_BOX_PADDING
    if (x2 > maxX) {
      maxX = x2
    }

    const y1 = rect.y - BOUNDING_BOX_PADDING
    if (minY === 0 || y1 < minY) {
      minY = y1
    }

    const y2 = rect.y + rect.height + BOUNDING_BOX_PADDING
    if (y2 > maxY) {
      maxY = y2
    }

    rects.push({ x1, x2, y1, y2, width: PDF_WIDTH, height: PDF_HEIGHT })
  })

  return {
    fieldIdentifier,
    position: {
      boundingRect: {
        x1: minX,
        x2: maxX,
        // This doesn't affect how the highlights are drawn, but means we will
        // `scrollTo` a position 150px above the highlight, which is nicer UX
        y1: minY - 150,
        y2: maxY,
        width: PDF_WIDTH,
        height: PDF_HEIGHT,
      },
      rects,
      pageNumber: boundingBox.pageNumber,
    },
    id: JSON.stringify(fieldIdentifier),
    content: {},
    comment: { text: "", emoji: "" },
  }
}

type HighlightWithFieldIdentifier<T> = IHighlight & {
  fieldIdentifier: T
}

export interface PDFViewerProps<T> {
  cardClassName?: string
  documentName: Document["name"]
  documentUrl: Document["url"]
  documentMimeType: Document["mimetype"]
  onDownloadClick?: () => void
  boundingBoxes: {
    boundingBox: BoundingBox
    fieldIdentifier: T
  }[]
  activeField: T | null
  onHighlightClick?: (fieldIdentifier: T) => void
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const PDFViewer = <T extends unknown>({
  cardClassName,
  documentName,
  documentUrl,
  documentMimeType,
  onDownloadClick,
  boundingBoxes,
  activeField,
  onHighlightClick,
}: PDFViewerProps<T>): ReactElement => {
  /**
   * Zoom
   * ====
   */
  const [zoom, setZoom] = useState<number>(1)

  const pdfHighlighterRef = useRef(null)

  // Force the PDF viewer to redraw when the zoom changes. This functionality
  // doesn't seem to be exposed any other way by the library.
  useEffect(() => {
    if (pdfHighlighterRef.current && zoom >= ZOOM_MIN && zoom <= ZOOM_MAX) {
      // The `handleScaleValue` nproperty isn't listed in the type definitions,
      // but does in fact exist.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      pdfHighlighterRef.current.handleScaleValue()
    }
  }, [zoom])

  /**
   * Performance
   * ===========
   */

  // We don't want to re-render the PDF document unless absolutely necessary,
  // because it's slow. One way to avoid that is to cache the highlights, which
  // never change after the initial calculation because they are derived from
  // the (static) bounding box information.
  const highlights = useMemo<HighlightWithFieldIdentifier<T>[]>(
    () => boundingBoxes.map(boundingBoxToHighlight),
    // Because the `boundingBoxes` may be derived from user-editable data
    // structures, we don't want to list them in the dependency array or it will
    // change too often. Instead the only case we need to watch for is the
    // number of bounding boxes changing, which may indicate we need to add or
    // remove some highlights.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [boundingBoxes.length],
  )

  // We also cache the document URL, because it has a token appended to the end
  // as a query parameter that changes often. If we happen to re-request the URL
  // through SWR and only the token has changed, we don't need to re-render. We
  // only re-render if the user has actually selected a different document.
  const [cachedUrl, setCachedUrl] = useState<Document["url"]>(documentUrl)
  useEffect(() => {
    const [documentUrlWithoutParams] = documentUrl.split("?")
    const [cachedUrlWithoutParams] = cachedUrl.split("?")

    if (documentUrlWithoutParams !== cachedUrlWithoutParams) {
      setCachedUrl(documentUrl)
    }
  }, [cachedUrl, documentUrl])

  /**
   * Scrolling
   * =========
   */

  const [scrollTo, setScrollTo] = useState<(h: IHighlight) => void>(
    () => () => {
      return
    },
  )

  // Try to scroll the PDF to the relevant highlight whenever the user interacts
  // with a data point
  useEffect(() => {
    if (activeField === null) {
      return
    }

    // If there are multiple highlights for this data point, scroll to the first
    const highlight = highlights.find(h => h.id === JSON.stringify(activeField))
    if (highlight) {
      try {
        scrollTo(highlight)
      } catch (e) {
        // The PDF library sometimes throws an error here, for reasons unknown
        // Ref: https://github.com/agentcooper/react-pdf-highlighter/blob/master/src/components/PdfHighlighter.tsx#L393
        // eslint-disable-next-line no-console
        console.error("Error in PdfHighlighter", e)
      }
    }
  }, [highlights, activeField, scrollTo])

  const pdfElementId = useId()

  return (
    <Card
      className={classNames(
        "relative flex-grow overflow-hidden",
        cardClassName,
      )}
      padding={0}
    >
      <PDFToolbar
        pdfElementId={pdfElementId}
        documentName={documentName}
        documentMimeType={documentMimeType}
        documentUrl={cachedUrl}
        zoom={zoom}
        setZoom={setZoom}
        onDownloadClick={onDownloadClick}
      />

      <div
        id={pdfElementId}
        className="absolute top-12 bottom-0 left-0 right-0 rounded-b-md bg-otto-grey-300"
      >
        <PdfLoader url={cachedUrl} beforeLoad={<Loading className="mt-4" />}>
          {pdfDocument => (
            <PdfHighlighter
              pdfDocument={pdfDocument}
              ref={pdfHighlighterRef}
              scrollRef={scrollTo => {
                setScrollTo(() => scrollTo)
              }}
              pdfScaleValue={zoom.toString()}
              enableAreaSelection={() => false}
              highlightTransform={highlight => (
                <Highlight
                  onClick={() => {
                    if (onHighlightClick) {
                      onHighlightClick(highlight.fieldIdentifier)
                    }
                  }}
                  isScrolledTo={dequal(activeField, highlight.fieldIdentifier)}
                  key={highlight.id}
                  position={highlight.position}
                  comment={highlight.comment}
                />
              )}
              highlights={highlights}
              onSelectionFinished={() => {
                return null
              }}
              onScrollChange={() => {
                return
              }}
            />
          )}
        </PdfLoader>
      </div>
    </Card>
  )
}

export default PDFViewer
