import React, { FC, ReactNode, useEffect, useId, useState } from "react"
import ModalTemplate from "src/components/ModalTemplate"
import { Button, ModalDescription, Toast } from "@appia/ui-components"
import ErrorMessage from "src/components/ErrorMessage"
import Loading from "src/components/Loading"

import useApiClient from "src/contexts/ApiClientContext"
import PBQAEntryForm from "./ContractPBQAForm"

import { ContractResponse, PBQASearchResult, createContract } from "@appia/api"
import { useSearchPBQAs } from "src/swr"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"

import usePageName from "src/contexts/PageNameContext"
import { logButtonClick } from "src/amplitude"
import { useNavigate } from "react-router-dom"
import { REVIEW_CONTRACT_DOCUMENTS_PATH } from "../../screens/ReviewContract"

const CONTAINER_NAME = "Contract modal"

const ContractModal: FC<{
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
  contractPolicyId: string
  triggerToast: (type: Toast.ToastType, msg: ReactNode) => void
}> = ({ isOpen, onClose, onSuccess, contractPolicyId, triggerToast }) => {
  const { request: pbqasRequest, update: updatePBQAs } = useSearchPBQAs({
    orderBy: "desc",
    order: "updated_at",
    policyId: [contractPolicyId],
  })

  const apiClient = useApiClient()
  const pageName = usePageName()
  const navigate = useNavigate()

  const searchResults: PBQASearchResult[] = RD.isSuccess(pbqasRequest)
    ? pbqasRequest.data.items
    : []

  const [completedPBQAs, notCompletedPBQAs] = searchResults.reduce<
    [PBQASearchResult[], PBQASearchResult[]]
  >(
    (acc, item) => {
      const [completed, notCompleted] = acc
      if (item.status === "review_complete") {
        completed.push(item)
      } else {
        notCompleted.push(item)
      }
      return [completed, notCompleted]
    },
    [[], []] as [PBQASearchResult[], PBQASearchResult[]],
  )

  const [selectedPBQAIds, setSelectedPBQAIds] = useState<
    Set<PBQASearchResult["id"]>
  >(new Set())

  const [createContractRequest, setCreateContractRequest] = useState<
    RD.RemoteData<Error, ContractResponse>
  >(RD.NotAsked)

  const [showSubmitError, setShowSubmitError] = useState<boolean>(false)

  useEffect(() => {
    if (selectedPBQAIds.size > 0) {
      setShowSubmitError(false)
    }
  }, [selectedPBQAIds, setShowSubmitError])

  const handleCheckboxChange = (pbqaId: string): void => {
    const selectedPBQAIdsCopy = new Set(selectedPBQAIds)
    if (selectedPBQAIds.has(pbqaId)) {
      selectedPBQAIdsCopy.delete(pbqaId)
    } else {
      selectedPBQAIdsCopy.add(pbqaId)
    }
    setSelectedPBQAIds(selectedPBQAIdsCopy)
  }

  const handleSelectAllCheckboxes = (checked: boolean): void => {
    if (checked) {
      setSelectedPBQAIds(new Set(completedPBQAs.map(pbqaEntry => pbqaEntry.id)))
    } else {
      setSelectedPBQAIds(new Set())
    }
  }

  useEffect(() => {
    const updatedSelectedPBQAIds = new Set<string>()
    completedPBQAs.forEach(pbqaEntry => {
      if (selectedPBQAIds.has(pbqaEntry.id)) {
        updatedSelectedPBQAIds.add(pbqaEntry.id)
      }
    })

    if (updatedSelectedPBQAIds.size !== selectedPBQAIds.size) {
      setSelectedPBQAIds(updatedSelectedPBQAIds)
    }
  }, [completedPBQAs, selectedPBQAIds])

  const handleContractSubmit = async (): Promise<void> => {
    logButtonClick({
      buttonName: "Next",
      containerName: CONTAINER_NAME,
      pageName,
    })
    if (selectedPBQAIds.size === 0) {
      setShowSubmitError(true)
      return
    }

    setCreateContractRequest(RD.Loading)
    const createContractRequest = {
      pbqaIds: Array.from(selectedPBQAIds),
    }
    try {
      const { data: createdContract } = await createContract(
        apiClient,
        createContractRequest,
      )
      setCreateContractRequest(RD.Success(createdContract))
      onSuccess()
      const contract = createdContract.contract
      navigate(
        `/contract/review/${contract.contractId}/${contract.version.versionId}/${REVIEW_CONTRACT_DOCUMENTS_PATH}`,
      )
    } catch (error) {
      if (error instanceof Error) {
        setCreateContractRequest(RD.Failure(error))
        Sentry.captureException(error)
      }
    }
  }

  const formId = useId()

  return (
    <ModalTemplate
      allowOverflow
      isOpen={isOpen}
      onClose={onClose}
      title="Select which associated PBQA entries to process"
      content={
        <ModalDescription as="div" className="grid gap-2">
          {RD.match(
            pbqasRequest,
            <Loading />,
            <Loading />,

            () => (
              <PBQAEntryForm
                formId={formId}
                onCheckboxChange={handleCheckboxChange}
                onSelectAllCheckboxes={handleSelectAllCheckboxes}
                selectedPBQAIds={selectedPBQAIds}
                completedPBQAs={completedPBQAs}
                notCompletedPBQAs={notCompletedPBQAs}
                triggerToast={triggerToast}
                updatePBQAs={updatePBQAs}
              />
            ),

            contractError => (
              <ErrorMessage
                message="Failed to load PBQA entries"
                error={contractError}
              />
            ),
          )}

          {RD.isFailure(createContractRequest) && (
            <ErrorMessage
              message="Failed to create Contract"
              error={createContractRequest.error}
            />
          )}

          {/* TODO: PP-141 - validation and error between header and entries */}
          {showSubmitError && (
            <ErrorMessage message="Please select one or more PBQA entries" />
          )}
        </ModalDescription>
      }
      actionsReflowBreakpoint="sm"
      actions={[
        <Button
          key="cancel"
          label="Cancel"
          theme="night"
          style="outlined"
          onClick={() => {
            onClose()
            logButtonClick({
              buttonName: "Cancel",
              containerName: CONTAINER_NAME,
              pageName,
            })
          }}
        />,

        <Button
          key="next"
          form={formId}
          label="Next"
          theme="pop"
          style="filled"
          onClick={async () => await handleContractSubmit()}
          isLoading={RD.isLoading(createContractRequest)}
        />,
      ]}
    />
  )
}

export default ContractModal
