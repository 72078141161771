import React, { useEffect, useState } from "react"
import FilesTable from "./FilesTable"
import { useNavigate, useParams } from "react-router-dom"
import { useGetContract } from "src/swr"
import * as RD from "@appia/remote-data"
import * as Sentry from "@sentry/react"
import useApiClient from "src/contexts/ApiClientContext"
import { Button, Callout, Card, Toast, UploadIcon } from "@appia/ui-components"
import {
  ContractFile,
  ContractFileType,
  ContractSectionItem,
  getContractUrl,
  unlinkFile,
} from "@appia/api"
import DocumentsUploadModal from "./DocumentsUploadModal"
import ConfirmDeleteModal from "./ConfirmDeleteModal"
import { REVIEW_CONTRACT_GENERAL_PATH } from ".."

import { useToastHandler } from "src/hooks/useToastHandler"
import ToastViewport from "src/components/ToastViewport"
import { mutate } from "swr"

const DocumentsScreen: React.FC = (): JSX.Element => {
  const apiClient = useApiClient()
  const navigate = useNavigate()
  const { contractId, version } = useParams<{
    contractId: string
    version: string
  }>()

  if (contractId === undefined) {
    throw new Error("Unknown contractId")
  }

  if (version === undefined) {
    throw new Error("Unknown version")
  }

  const versionNumber = parseInt(version)

  if (isNaN(versionNumber)) {
    throw new Error("Unknown version number")
  }

  const { request: contract } = useGetContract(contractId)
  const isValidContract = RD.isSuccess(contract)

  const files = isValidContract ? contract?.data?.files : []
  const sections = isValidContract ? contract.data?.sections : []
  const umr = isValidContract
    ? contract.data?.contract.uniqueMarketReference
    : ""

  const [showMissingSlipBanner, setShowMissingSlipBanner] = useState(false)
  const [showMissingSOVBanner, setShowMissingSOVBanner] = useState(false)
  const [
    showPreDocumentFunctionalityBanner,
    setShowPreDocumentFunctionalityBanner,
  ] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [fileToUnlink, setFileToUnlink] = useState<ContractFile | null>(null)

  const { toastType, toastMessage, toastState, setAndTriggerToast } =
    useToastHandler()

  const toggleUploadModal = (): void => {
    setShowUploadModal(!showUploadModal)
  }

  const groupClassesRequiringSOV = [
    "cargo",
    "hull",
    "property_uk",
    "terrorism",
    "commercial_na_open_market",
    "commercial_worldwide",
  ]

  const groupClassNeedsSOV = (
    sections: ContractSectionItem[],
    groupClassesRequiringSOV: string[],
  ): boolean => {
    return sections.some(section =>
      section.lines.some(line => {
        const groupClass = line.syndicateData.syndicateData.groupClass
        return groupClass && groupClassesRequiringSOV.includes(groupClass)
      }),
    )
  }

  useEffect(() => {
    const isMissingSlip = !files?.some(
      file => file.type === ("STAMPED_SLIP" as keyof typeof ContractFileType),
    )
    const isMissingSOV = !files?.some(file => file.type === "SOV")
    const needsSOV =
      isMissingSOV && groupClassNeedsSOV(sections, groupClassesRequiringSOV)

    setShowMissingSlipBanner(isMissingSlip)
    setShowMissingSOVBanner(needsSOV)

    const documentFilesFeatureDate = new Date("2024-12-20")
    const contractCreationDate =
      isValidContract && new Date(contract?.data.contract?.version.createdAt)

    setShowPreDocumentFunctionalityBanner(
      contractCreationDate && contractCreationDate < documentFilesFeatureDate,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, sections])

  const onCancel = (): void => {
    navigate("/contract")
  }

  const onSubmit = (): void => {
    navigate(`../${REVIEW_CONTRACT_GENERAL_PATH}`)
  }

  const handleUnlink = async (file: ContractFile): Promise<void> => {
    try {
      await unlinkFile(apiClient, {
        file_id: file.id,
        contract_version_id: Number(version),
      })
      setAndTriggerToast("success", "Document has been successfully deleted")
      await mutate(getContractUrl(contractId))
    } catch (error) {
      setAndTriggerToast("error", "Failed to delete document")
      Sentry.captureException(error)
    }
  }

  const onUnlink = (file: ContractFile): void => {
    setFileToUnlink(file)
    if (file.uploadedToPartnerIngestionDate) {
      setShowDeleteModal(true)
    } else {
      handleUnlink(file)
    }
  }

  return (
    <>
      <div className="pt-5"></div>
      <Card className="w-1/2" padding={0}>
        <section className="mb-4 border-b border-otto-grey-400 p-4 pb-4 ">
          <h2 className="text-xl font-bold">Documents on this contract</h2>
        </section>

        {showPreDocumentFunctionalityBanner ? (
          <Callout type="information" className="m-4 p-4">
            <div className="font-bold">
              This contract was created before this documents functionality was
              built
            </div>
            <div>
              Any documents already sent to partners may not be shown here.
            </div>
          </Callout>
        ) : (
          <>
            {showMissingSlipBanner && (
              <Callout type="warning" className="m-4 p-4">
                <div className="font-bold">Stamped slip is missing</div>
                <div>The stamped slip is required for all risks.</div>
              </Callout>
            )}

            {showMissingSOVBanner && (
              <Callout type="warning" className="m-4 p-4">
                <div className="font-bold">SOV is missing</div>
                <div>
                  The SOV is needed for this class of business. Upload if
                  necessary.
                </div>
              </Callout>
            )}
          </>
        )}

        <FilesTable files={files} onDelete={onUnlink} />
        <div className="mb-4 border-b border-otto-grey-400 pb-4 "></div>
        <div className="flex justify-end p-3 pt-0">
          <Button
            label="Upload a document"
            style="outlined"
            theme="night"
            onClick={toggleUploadModal}
            icon={{
              position: "left",
              icon: <UploadIcon />,
            }}
          />
        </div>
      </Card>
      <DocumentsUploadModal
        isOpen={showUploadModal}
        onClose={toggleUploadModal}
        contractVersionId={versionNumber}
        umr={umr}
      />
      {fileToUnlink && (
        <ConfirmDeleteModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          fileName={fileToUnlink.name}
          onDeleteConfirm={() => {
            handleUnlink(fileToUnlink)
            setShowDeleteModal(false)
          }}
        />
      )}
      <Card className="mt-6 flex w-1/2 justify-end">
        <div className="flex w-3/12 gap-2">
          <Button
            key="cancel"
            label="Cancel"
            theme="night"
            style="outlined"
            stretch="space-between"
            className="!inline-block"
            onClick={onCancel}
          />
          <Button
            key="next"
            label="Next"
            theme="pop"
            style="filled"
            stretch="space-between"
            className="!inline-block"
            onClick={onSubmit}
          />
        </div>
      </Card>

      <Toast.Toast
        type={toastType}
        message={toastMessage}
        open={toastState.open}
        onOpenChange={toastState.onOpenChange}
      />

      <ToastViewport />
    </>
  )
}

export default DocumentsScreen
