import axios, { AxiosInstance, AxiosPromise } from "axios"
import { Broker } from "../Broker"
import { EndorsementType } from "./Search"
import { EndorsementStatus } from "./Status"
import { Policy, PolicyReferencesForSyndicate, PolicySource } from "../Policies"
import { KiClassOfBusiness } from "../PBQA"

export const ENDORSEMENT_UPLOAD_TYPE_REQUEST = "Request"
export const ENDORSEMENT_UPLOAD_TYPE_NOTICE = "Notice"

export type EndorsementUploadType =
  | typeof ENDORSEMENT_UPLOAD_TYPE_REQUEST
  | typeof ENDORSEMENT_UPLOAD_TYPE_NOTICE

export const SOURCE_UPLOAD_TYPE_PPL = "PPL"
export const SOURCE_UPLOAD_TYPE_WHITESPACE = "Whitespace"

export type SourceUploadType =
  | typeof SOURCE_UPLOAD_TYPE_PPL
  | typeof SOURCE_UPLOAD_TYPE_WHITESPACE

interface Email {
  referralEmail: string
  referralName: string
}

interface Referral {
  body: string
  emails: Email[]
  referredAt: string
  referredBy: string
  subject: string
}

type PolicyData = Pick<
  Policy,
  "inceptionDate" | "policyId" | "policyReference" | "umr"
> & {
  insured: string | null
  source: PolicySource | null
}

export interface Endorsement {
  acceptedBy: string | null
  broker: Broker | null
  completedAt: string | null
  completedBy: string | null
  id: string
  platformUrl: string | null
  policyData: PolicyData | null
  policyId: string | null
  policyReferences: PolicyReferencesForSyndicate[]
  referrals: Referral[]
  rejectedBy: string | null
  rejectionReason: string | null
  status: EndorsementStatus
  teamId: string
  type: EndorsementType
  umr: string | null
  number: string | null
}

export const getEndorsementUrl = (endorsement_id: Endorsement["id"]): string =>
  `/api/endorsements/${endorsement_id}`

interface EndorsementCreateRequest {
  name: string
  companyId: string
  teamId: string
  umr: string
  insuredName: string
  endorsementNumber: string
  endtType: string
  extension: string
  mimetype: string
  size: number
  url: string | null
  source: string
  externalId: string
}

interface EndorsementUploadUrlResponse {
  id: string
  endorsement_id: string
  url: string
  fields: Record<string, string>
}

const toSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

const transformEndorsementCreateRequestValue = (
  key: string,
  value: string,
): string => {
  const lowerCaseKeys = new Set(["source", "endtType"])

  return lowerCaseKeys.has(key) ? value.toLowerCase() : value
}

const transformUploadRequest = (
  data: EndorsementCreateRequest,
): Record<string, unknown> => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    const transformedKey = toSnakeCase(key)
    acc[transformedKey] = transformEndorsementCreateRequestValue(key, value)
    return acc
  }, {} as Record<string, string | number | null>)
}

export const generateEndorsementUploadUrl = (
  client: AxiosInstance,
  body: EndorsementCreateRequest,
): AxiosPromise<EndorsementUploadUrlResponse> =>
  client.post(`/api/v1/endorsements/upload`, transformUploadRequest(body), {
    transformResponse: axios.defaults.transformResponse,
  })

interface SelectOption {
  value: string
  label: string
}

export enum KiDivision {
  Casualty = "casualty",
  Specialty = "specialty",
  Property = "property",
}

export const kiDivisionOptions: Record<KiDivision, string> = {
  [KiDivision.Casualty]: "Casualty",
  [KiDivision.Specialty]: "Specialty",
  [KiDivision.Property]: "Property",
}

export const kiCasualtyDivisionOptions: Partial<
  Record<KiClassOfBusiness, string>
> = {
  ambridge_cyber_tech: "Ambridge Cyber Tech",
  ambridge_excess_casualty: "Ambridge Excess Casualty",
  ambridge_general_liability: "Ambridge General Liability",
  ambridge_liability_package_sir: "Ambridge Liability Package SIR",
  ambridge_professional_indemnity_us: "Ambridge Professional Indemnity US",
  ambridge_property_package_sir: "Ambridge Property Package SIR",
  ambridge_small_na_liability: "Ambridge Small NA Liability",
  ambridge_transactional: "Ambridge Transactional",
  british_cyber_attack_plus: "BCAP",
  casualty_treaty_lt_cat: "Casualty Treaty LT Cat",
  casualty_treaty_lt_pro_rata: "Casualty Treaty LT Pro Rata",
  casualty_treaty_lt_risk: "Casualty Treaty LT Risk",
  casualty_treaty_st_cat: "Casualty Treaty ST Cat",
  casualty_treaty_st_risk: "Casualty Treaty ST Risk",
  cyber_british_cyber_attack_plus: "Cyber British Cyber Attack Plus",
  cyber_privacy_tech: "Cyber Privacy Tech",
  directors_officers_worldwide: "D&O WW",
  financial_institutions: "Financial Institutions",
  healthcare_liability: "Healthcare Liability",
  professional_indemnity_us: "Professional Indemnity US",
  professional_liability_international: "Professional Liability International",
  scion_casualty: "SCION Casualty",
  small_na_liability: "Small NA Liability",
}

export const kiPropertyDivisionOptions: Partial<
  Record<KiClassOfBusiness, string>
> = {
  commercial_na_facilities: "Commercial NA Facilities",
  commercial_na_open_market: "Commercial NA Open Market",
  commercial_worldwide: "Commercial WW",
  flood: "Flood",
  household_hv: "Household HV",
  household_na: "Household NA",
  property_financial: "Property Financial",
  property_liability_us_scgl: "Property Liability US SCGL",
  property_uk: "Property UK",
  transportation: "Transportation",
}

export const kiSpecialtyDivisionOptions: Partial<
  Record<KiClassOfBusiness, string>
> = {
  ambridge_terrorism: "Ambridge Terrorism",
  cargo: "Cargo",
  contingency: "Contingency",
  energy_construction: "Energy Construction",
  energy_gom_wind: "Energy Gom Wind",
  energy_liability: "Energy Liability",
  energy_midstream: "Energy Midstream",
  energy_operational: "Energy Operational",
  energy_renewable: "Energy Renewable",
  hull: "Hull",
  marine_liability: "Marine Liability",
  marine_war: "Marine War",
  nuclear: "Nuclear",
  personal_accident: "Personal Accident",
  specie: "Specie",
  terrorism: "Terrorism",
}

export const kiDivisionGroupClassMapping: Record<
  KiDivision,
  Partial<Record<KiClassOfBusiness, string>>
> = {
  casualty: kiCasualtyDivisionOptions,
  specialty: kiSpecialtyDivisionOptions,
  property: kiPropertyDivisionOptions,
}

export const ENDORSEMENT_GROUP_CLASS_OPTIONS: SelectOption[] = Object.entries(
  kiDivisionGroupClassMapping,
).flatMap(([divisionKey, groupClasses]) => {
  const divisionOption: SelectOption = {
    value: divisionKey,
    label: kiDivisionOptions[divisionKey as KiDivision],
  }

  const groupClassOptions: SelectOption[] = Object.entries(groupClasses).map(
    ([groupClassKey, groupClassLabel]) => ({
      value: groupClassKey,
      label: groupClassLabel,
    }),
  )

  return [divisionOption, ...groupClassOptions]
})
