import { FC } from "react"
import { PolicyReferencesForSyndicate } from "@appia/api"
import StaticTable from "./StaticTable"
import { formatPercentage4DP } from "../utils/prettyPrinters"
import { sortPolicyReferencesBySyndicateName } from "src/utils/sortPolicyReferencesBySyndicateName"

// Hardcoded list of partners in the order they are added in placement platforms
const syndicateNameOrder = [
  "Ki Insurance",
  "Aspen Managing Agency Ltd",
  "Travelers Syndicate Management Ltd",
  "Beazley Furlonge Ltd",
  "QBE Underwriting Limited",
]

const PolicyReferencesTable: FC<{
  policyReferences?: PolicyReferencesForSyndicate[]
  showWrittenLineSize?: boolean
}> = ({ policyReferences = [], showWrittenLineSize = false }) => {
  const maxNumReferences = policyReferences.reduce(
    (acc, pr) => Math.max(pr.policyReferences.length, acc),
    0,
  )

  const headers = [
    "Syndicate",
    ...Array.from(new Array(maxNumReferences)).map(
      (_, index) => `Section ${index + 1} Ref`,
    ),
  ]

  if (showWrittenLineSize) {
    headers.push("Written Line")
  }

  const sortedPolicyReferences = sortPolicyReferencesBySyndicateName(
    policyReferences,
    syndicateNameOrder,
  )

  const data = sortedPolicyReferences.map(
    ({ syndicate, policyReferences: refs, writtenLineSize }) => {
      const paddedReferences = [
        ...refs.map(
          r =>
            `${r.policyReference} ${
              r.eea === true ? "(EEA)" : r.eea === false ? "(non-EEA)" : ""
            }`,
        ),
        // Add empty strings to fill missing references
        ...Array(maxNumReferences - refs.length).fill(""),
      ]

      const result = [
        `${syndicate.name} (${syndicate.code})`,
        ...paddedReferences,
      ]

      if (showWrittenLineSize) {
        result.push(
          writtenLineSize ? formatPercentage4DP(writtenLineSize) : "-",
        )
      }

      return result
    },
  )

  return (
    <StaticTable
      caption="Policy References Table"
      headers={headers}
      data={data}
    />
  )
}

export default PolicyReferencesTable
