import * as React from "react"
import { createRoot } from "react-dom/client"
import {
  BrowserRouter,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { Replay } from "@sentry/replay"

import { Client } from "@appia/api"
import { Toast } from "@appia/ui-components"
import { ApiClientContext } from "./contexts/ApiClientContext"
import ErrorBoundary from "./templates/ScreenErrorBoundary"

import appRoutes from "./Routes"

import "./index.css"
import "./App.css"

import { init as initAmplitude } from "./amplitude"
import { UserInitializer } from "./hooks/useLaunchDarkly"
import { LDProvider } from "launchdarkly-react-client-sdk"

Sentry.init({
  enabled:
    window.SENTRY_ENVIRONMENT === "production" ||
    window.SENTRY_ENVIRONMENT === "staging",
  dsn: window.WEB_SENTRY_DSN || window.SENTRY_DSN,
  environment: window.SENTRY_ENVIRONMENT,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
      tracingOrigins: [window.SENTRY_ORIGIN],
    }),
    new Replay({
      maskAllText: false,
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    }),
  ],

  // Set sample rates to 1.0 to capture 100% of transactions for performance
  // monitoring
  tracesSampleRate: window.SENTRY_RATE,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: window.SENTRY_RATE,
})

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

initAmplitude(window.AMPLITUDE_KEY)

const apiClient = Client(window.HOST)

const container = document.getElementById("root")
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <LDProvider clientSideID={window.LAUNCH_DARKLY_CLIENT_ID}>
      <ApiClientContext.Provider value={apiClient}>
        <ErrorBoundary>
          <Toast.Provider>
            <BrowserRouter>
              <SentryRoutes>{appRoutes}</SentryRoutes>
            </BrowserRouter>
          </Toast.Provider>
        </ErrorBoundary>
        <UserInitializer />
      </ApiClientContext.Provider>
    </LDProvider>
  </React.StrictMode>,
)
