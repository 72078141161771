import { FC } from "react"
import { Button } from "@appia/ui-components"
import ModalTemplate from "src/components/ModalTemplate"

interface ConfirmDeleteModalProps {
  isOpen: boolean
  onClose: () => void
  fileName: string | null
  onDeleteConfirm: () => void
}

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({
  isOpen,
  onClose,
  fileName,
  onDeleteConfirm,
}) => {
  return (
    <ModalTemplate
      isOpen={isOpen}
      onClose={onClose}
      title="Delete from this contract"
      content={
        <div className="px-4">
          <p>
            <strong>{fileName}</strong> has already been sent to the partners
            and will only be deleted from this contract. You can manually delete
            the document in the partner portal.
          </p>
          <p className="mt-2 font-bold">Continue to delete?</p>
        </div>
      }
      actions={[
        <Button
          data-testid="cancel-confirm-delete-button"
          key="cancel"
          label="Cancel"
          theme="night"
          style="outlined"
          onClick={onClose}
        />,
        <Button
          key="delete"
          label="Delete"
          theme="destructive"
          style="filled"
          onClick={onDeleteConfirm}
        />,
      ]}
    />
  )
}

export default ConfirmDeleteModal
