import React, { Dispatch, FC, SetStateAction, useId, useState } from "react"

import {
  Button,
  Card,
  PlusIcon,
  ToggleRadioButtons,
} from "@appia/ui-components"

import FormCTAs from "../FormCTAs"

import KiQuoteSearchBar from "./KiQuoteSearchBar"
import ComparisonGroup from "./ComparisonGroup"
import NonComparisonGroup from "./NonComparisonGroup"
import SyndicateModal from "./SyndicateModal"
import CollapsibleSyndicatePanel from "./CollapsibleSyndicatePanel"

import {
  KiQuote,
  PBQAAnswer,
  PBQAQuestion,
  PBQASurvey,
  Syndicate,
} from "@appia/api"
import * as RD from "@appia/remote-data"
import { useGetPBQAInfo } from "src/swr"

import usePBQASurvey from "ReviewPBQA/PBQASurveyContext"
import {
  PBQAReviewState,
  QuestionAnswerState,
  QuestionAnswerStateSyndicateSpecific,
  QuoteComparisonMatchModalData,
  createQuoteComparisonMatchModalData,
  flattenStatesForSyndicate,
  groupQuestionStatesByLayout,
  groupQuestionStatesBySyndicateSpecificity,
} from "ReviewPBQA/state"
import type { PBQASurveyWithDetails } from "ReviewPBQA/useGetSurveys"
import { KiQuoteSearchFilters } from "ReviewPBQA/kiQuoteSearch"
import QuoteLineMatchingModal from "./QuoteLineMatchingModal/QuoteLineMatchingModal"
import { HeaderData } from "./QuoteLineMatchingModal/QuoteMatchingHeader"

const SyndicateGroup: FC<{
  onChangeAnswer: (questionId: PBQAQuestion["id"], answer: PBQAAnswer) => void
  onJumpToBoundingBox: (questionId: PBQAQuestion["id"]) => void
  onManageSyndicates: () => void
  questionStates: QuestionAnswerStateSyndicateSpecific[]
  selectedKiQuote: KiQuote | null
  showFieldErrors: boolean
  syndicates: Syndicate[]
}> = ({
  onChangeAnswer,
  onJumpToBoundingBox,
  onManageSyndicates,
  questionStates,
  selectedKiQuote,
  showFieldErrors,
  syndicates,
}) => {
  return (
    <div>
      {syndicates.map(syndicate => {
        const statesForSyndicate = flattenStatesForSyndicate(
          questionStates,
          syndicate.id,
        )

        return (
          <CollapsibleSyndicatePanel key={syndicate.id} syndicate={syndicate}>
            <LayoutGroup
              onChangeAnswer={onChangeAnswer}
              onJumpToBoundingBox={onJumpToBoundingBox}
              questionStates={statesForSyndicate}
              selectedKiQuote={selectedKiQuote}
              showFieldErrors={showFieldErrors}
            />
          </CollapsibleSyndicatePanel>
        )
      })}

      <div className="border-b border-b-otto-grey-300 p-2 text-right">
        <Button
          icon={{ position: "right", icon: <PlusIcon /> }}
          label="Manage syndicates"
          onClick={onManageSyndicates}
          size="small"
          style="text"
          theme="night"
        />
      </div>
    </div>
  )
}

const LayoutGroup: FC<{
  onChangeAnswer: (questionId: PBQAQuestion["id"], answer: PBQAAnswer) => void
  onJumpToBoundingBox: (questionId: PBQAQuestion["id"]) => void
  questionStates: QuestionAnswerState[]
  selectedKiQuote: KiQuote | null
  showFieldErrors: boolean
}> = ({
  onChangeAnswer,
  onJumpToBoundingBox,
  questionStates,
  selectedKiQuote,
  showFieldErrors,
}) => {
  const questionStateGroups = groupQuestionStatesByLayout(questionStates)

  return (
    <div>
      {questionStateGroups.map(({ states, layout }, i) =>
        layout === "comparison" ? (
          <ComparisonGroup
            key={i}
            onChangeAnswer={onChangeAnswer}
            onJumpToBoundingBox={onJumpToBoundingBox}
            questionStates={states}
            showFieldErrors={showFieldErrors}
          />
        ) : (
          <NonComparisonGroup
            key={i}
            onChangeAnswer={onChangeAnswer}
            onJumpToBoundingBox={onJumpToBoundingBox}
            questionStates={states}
            quote={selectedKiQuote}
            showFieldErrors={showFieldErrors}
          />
        ),
      )}
    </div>
  )
}

const FormSection: FC<{
  confirmRequest: RD.RemoteData<Error, unknown>
  onChangeAnswer: (questionId: PBQAQuestion["id"], answer: PBQAAnswer) => void
  onChangeSurvey: (surveyId: PBQASurvey["id"]) => void
  onJumpToBoundingBox: (questionId: PBQAQuestion["id"]) => void
  onManuallyChangeQuote: (q: KiQuote) => void
  onSaveDraft: () => Promise<void>
  onSubmitFinalSection: () => Promise<void>
  onSubmitPrecedingSection: () => Promise<void>
  quoteSearchBarTestId: string
  saveDraftRequest: RD.RemoteData<Error, unknown>
  searchAndSetResults: (q: string) => Promise<void>
  searchFilters: Partial<KiQuoteSearchFilters>
  searchResults: KiQuote[]
  searchText: string
  selectedKiQuote: KiQuote | null
  selectedSyndicates: Syndicate[]
  setSearchFilters: Dispatch<SetStateAction<Partial<KiQuoteSearchFilters>>>
  setSearchText: Dispatch<SetStateAction<string>>
  showFieldErrors: boolean
  state: PBQAReviewState
  surveys: PBQASurveyWithDetails[]
}> = ({
  confirmRequest,
  onChangeAnswer,
  onChangeSurvey,
  onJumpToBoundingBox,
  onManuallyChangeQuote,
  onSaveDraft,
  onSubmitFinalSection,
  onSubmitPrecedingSection,
  quoteSearchBarTestId,
  saveDraftRequest,
  searchAndSetResults,
  searchFilters,
  searchResults,
  searchText,
  selectedKiQuote,
  selectedSyndicates,
  setSearchFilters,
  setSearchText,
  showFieldErrors,
  state,
  surveys,
}) => {
  const { activeSurvey, activeSectionIdx, pbqaId } = usePBQASurvey()
  const { update: updateSyndicates } = useGetPBQAInfo(pbqaId)

  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const isFirstSection = activeSectionIdx === 0
  const isLastSection =
    activeSectionIdx === activeSurvey.groupingHeaders.length - 1

  const questionStates = activeSurvey.questions
    .filter(q => q.grouping === activeSectionIdx)
    .map(({ id }) => state[id])

  const questionStateGroups =
    groupQuestionStatesBySyndicateSpecificity(questionStates)

  const sectionTitle = activeSurvey.groupingHeaders[activeSectionIdx]

  const sectionHeadingId = useId()
  const formHeadingId = useId()

  const [showMatchingModal, setShowMatchingModal] = useState<boolean>(false)

  const [quoteData, setQuoteData] = useState<QuoteComparisonMatchModalData[]>()
  const [headerData, setHeaderData] = useState<HeaderData>()
  const [kiQuote, setKiQuote] = useState<KiQuote>()
  const [open, setOpen] = useState<boolean>(false)

  const handleOpenModal = (
    searchQuoteData: KiQuote | undefined,
    quoteMatchResult: number,
  ): void => {
    setKiQuote(searchQuoteData)

    if (searchQuoteData) {
      const quoteData = createQuoteComparisonMatchModalData(
        searchQuoteData,
        state,
        activeSurvey,
      )
      if (quoteData) {
        setQuoteData(quoteData)
      }

      const fon = searchQuoteData.syndicateLines?.some(
        line => !!line?.firmOrderNoticeRequestedAt,
      )

      const headerData: HeaderData = {
        assuredName: searchQuoteData.assuredName,
        quoteId: searchQuoteData.quoteId,
        quoteLineId: searchQuoteData.quoteLineId,
        fon,
        match: quoteMatchResult,
      }

      setHeaderData(headerData)
    }

    setShowMatchingModal(true)
  }

  const [hasConfirmedQuoteDetailsModal, setHasConfirmedQuoteDetailsModal] =
    useState(false)

  return (
    <section
      className="absolute h-full w-full overflow-clip rounded-md"
      aria-labelledby={sectionHeadingId}
    >
      <h2 id={sectionHeadingId} className="sr-only">
        PBQA questions
      </h2>

      <Card className="max-h-full overflow-y-auto" padding={0}>
        {isFirstSection && (
          <>
            <KiQuoteSearchBar
              onManuallyChangeQuote={onManuallyChangeQuote}
              selectedQuote={selectedKiQuote}
              state={state}
              searchResults={searchResults}
              quoteSearchBarTestId={quoteSearchBarTestId}
              searchAndSetResults={searchAndSetResults}
              activeSurvey={activeSurvey}
              searchText={searchText}
              setSearchText={setSearchText}
              searchFilters={searchFilters}
              setSearchFilters={setSearchFilters}
              handleOpenModal={handleOpenModal}
              setOpen={setOpen}
              open={open}
              setHasConfirmedQuoteDetailsModal={
                setHasConfirmedQuoteDetailsModal
              }
              hasConfirmedQuoteDetailsModal={hasConfirmedQuoteDetailsModal}
            />
            {surveys.length > 1 && (
              <div className="overflow-y-auto border-b border-otto-grey-400 px-4 py-3">
                <ToggleRadioButtons
                  legend="PBQA survey"
                  onSelect={onChangeSurvey}
                  options={surveys.map(s => ({
                    label: s.slug,
                    value: s.id,
                  }))}
                  selectedValue={activeSurvey.id}
                  visuallyHideLegend
                />
              </div>
            )}
          </>
        )}

        <form
          aria-labelledby={formHeadingId}
          noValidate
          onSubmit={async e => {
            e.preventDefault()
            if (isLastSection) {
              await onSubmitFinalSection()
            } else {
              onSubmitPrecedingSection()
            }
          }}
        >
          <h3
            id={formHeadingId}
            className="border-b border-otto-grey-400 py-4 px-2 font-bold"
          >
            {sectionTitle}
          </h3>

          {questionStateGroups.map((group, i) =>
            group.isSyndicateSpecific ? (
              <SyndicateGroup
                key={i}
                onChangeAnswer={onChangeAnswer}
                onJumpToBoundingBox={onJumpToBoundingBox}
                onManageSyndicates={() => setModalOpen(true)}
                questionStates={group.states}
                selectedKiQuote={selectedKiQuote}
                showFieldErrors={showFieldErrors}
                syndicates={selectedSyndicates}
              />
            ) : (
              <LayoutGroup
                key={i}
                onChangeAnswer={onChangeAnswer}
                onJumpToBoundingBox={onJumpToBoundingBox}
                questionStates={group.states}
                selectedKiQuote={selectedKiQuote}
                showFieldErrors={showFieldErrors}
              />
            ),
          )}

          <FormCTAs
            confirmRequest={confirmRequest}
            mode={isLastSection ? "complete" : "continue"}
            onSaveDraft={onSaveDraft}
            saveDraftRequest={saveDraftRequest}
            showFieldErrors={showFieldErrors}
          />

          {modalOpen && (
            <SyndicateModal
              isOpen={modalOpen}
              existingSyndicates={selectedSyndicates}
              onClose={() => setModalOpen(false)}
              onSuccess={async () => {
                await updateSyndicates()
                setModalOpen(false)
              }}
            />
          )}
          {showMatchingModal && (
            <div>
              <QuoteLineMatchingModal
                isOpen={showMatchingModal}
                onClose={() => {
                  setShowMatchingModal(false)
                }}
                onLeave={() => {
                  setShowMatchingModal(false)
                }}
                onSubmit={onManuallyChangeQuote}
                quoteData={quoteData}
                headerData={headerData}
                kiQuote={kiQuote}
                setOpen={setOpen}
                setHasConfirmedQuoteDetailsModal={
                  setHasConfirmedQuoteDetailsModal
                }
              />
            </div>
          )}
        </form>
      </Card>
    </section>
  )
}

export default FormSection
