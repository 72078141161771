import React from "react"
import { Button, Toast, TrashIcon } from "@appia/ui-components"
import { prettyPrintDateString } from "src/utils/prettyPrinters"
import {
  ContractFile,
  ContractFileSource,
  ContractFileType,
  downloadContractFile,
} from "@appia/api"
import DownloadFileButton from "./DownloadFileButton"
import { useToastHandler } from "src/hooks/useToastHandler"
import useApiClient from "src/contexts/ApiClientContext"
import * as Sentry from "@sentry/react"
import { downloadFile } from "src/utils/download"
import ToastViewport from "src/components/ToastViewport"

const fileHeaders = [
  "Name",
  "Type",
  "Source",
  "Uploaded on",
  "Sent to partners?",
]

interface FilesTableProps {
  files?: ContractFile[] | null
  onDelete: (file: ContractFile) => void
}

const FilesTable: React.FC<FilesTableProps> = ({
  files,
  onDelete,
}): JSX.Element => {
  const { toastType, toastMessage, toastState, setAndTriggerToast } =
    useToastHandler()

  const apiClient = useApiClient()

  const handleDownloadClick = async (file: ContractFile): Promise<void> => {
    try {
      const response = await downloadContractFile(apiClient, file.id)

      const fileRes = await fetch(response.data.url)
      const mimeType =
        fileRes.headers.get("Content-Type") || "application/octet-stream"

      await downloadFile(response.data.url, file.name, mimeType)
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes("404")) {
          setAndTriggerToast("error", "File ID does not exist")
        } else {
          setAndTriggerToast("error", "Failed to download file")
        }
        Sentry.captureException(error)
      }
    }
  }

  return (
    <>
      <div className="m-4 grid grid-cols-7 items-center pt-2">
        {fileHeaders.map((header, index) => (
          <div
            key={index}
            className={index === 0 ? "col-span-2" : "col-span-1"}
          >
            <b>{header}</b>
          </div>
        ))}
      </div>
      {files && files.length > 0 ? (
        files.map((file, index) => (
          <div className="m-4 grid grid-cols-7 items-center" key={index}>
            <div className="col-span-2 pr-3">{file.name}</div>
            <div>{ContractFileType[file.type]}</div>
            <div>{ContractFileSource[file.source]}</div>
            <div>{prettyPrintDateString(file.updatedAt.toString())}</div>
            <div data-testid="sent-to-partners-value">
              {file.uploadedToPartnerIngestionDate ? "Yes" : "No"}
            </div>
            <div className="flex justify-end pr-1">
              <DownloadFileButton
                file={file}
                onDownloadClick={handleDownloadClick}
              />

              <Button
                data-testid="file-delete-button"
                label=""
                style="text"
                theme="night"
                onClick={() => {
                  onDelete(file)
                }}
                icon={{
                  position: "left",
                  icon: <TrashIcon className="h-5 w-5 justify-end" />,
                }}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="p-4 text-center">No documents in this contract.</div>
      )}
      <Toast.Toast
        type={toastType}
        message={toastMessage}
        open={toastState.open}
        onOpenChange={toastState.onOpenChange}
      />
      <ToastViewport />
    </>
  )
}

export default FilesTable
