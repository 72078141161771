import { FC, SetStateAction } from "react"
import CollapsibleSectionPanel from "./CollapsibleSectionPanel"
import LayerDetailsPanel from "./LayerDetailsPanel"
import ContractSectionsPanel from "./ContractSectionsPanel"
import { LayerFormField, SectionFormField } from "./utils/types"
import { MAJOR_CLASS_CODE_DESCRIPTION_MAPPING } from "@appia/api"
import {
  ValueAndCurrency,
  calculatePremiumAllocationAmount,
  calculatePremiumAllocationPercentage,
  parseEeaOrRow,
  parseMinorClass,
  parsePolicyReference,
  parseRenewalContract,
  parseSectionPercentage,
  parseSectionValueAndCurrency,
  parseSubClass,
} from "./parsers"

interface ContractSectionLayerProps {
  openLayer: LayerFormField
  setLayerFormFields: (
    setStateFn: SetStateAction<Record<string, LayerFormField>>,
  ) => void
}

const ContractSectionLayer: FC<ContractSectionLayerProps> = ({
  openLayer,
  setLayerFormFields,
}) => {
  const handleRenewalFieldChange = (value: string): void => {
    const selectedValue = value == "renewal"
    setLayerFormFields(prevState => ({
      ...prevState,
      [openLayer.layerId]: {
        ...prevState[openLayer.layerId],
        renewalContract: {
          raw: selectedValue,
          validated: parseRenewalContract(selectedValue),
          showError: false,
        },
      },
    }))
  }

  const handleMinorClassChange = (value: string): void => {
    setLayerFormFields(prevState => ({
      ...prevState,
      [openLayer.layerId]: {
        ...prevState[openLayer.layerId],
        minorClass: {
          raw: value,
          validated: parseMinorClass(value),
          showError: false,
        },
      },
    }))
  }

  const handleSubClassChange = (value: string): void => {
    setLayerFormFields(prevState => ({
      ...prevState,
      [openLayer.layerId]: {
        ...prevState[openLayer.layerId],
        subClass: {
          raw: value,
          validated: parseSubClass(value),
          showError: false,
        },
      },
    }))
  }

  interface PremiumAllocationFields {
    premiumAllocationValueAndCurrency: SectionFormField["premiumAllocationValueAndCurrency"]
    premiumAllocationPercent: SectionFormField["premiumAllocationPercent"]
  }

  const updatePremiumAllocationValueAndCurrency = (
    value: ValueAndCurrency,
    sectionFormFields: SectionFormField,
  ): PremiumAllocationFields => {
    const premiumAllocationValueAndCurrency = {
      raw: value,
      validated: parseSectionValueAndCurrency(value),
      showError: false,
    }

    const rawPercentage = calculatePremiumAllocationPercentage(
      premiumAllocationValueAndCurrency,
      sectionFormFields.layerWrittenPremium,
    )

    const premiumAllocationPercent = {
      raw: rawPercentage,
      validated: parseSectionPercentage(rawPercentage),
      showError: false,
    }

    return {
      premiumAllocationValueAndCurrency,
      premiumAllocationPercent,
    }
  }

  const updatePremiumAllocationPercent = (
    value: number,
    sectionFormFields: SectionFormField,
  ): PremiumAllocationFields => {
    const premiumAllocationPercent = {
      raw: value,
      validated: parseSectionPercentage(value),
      showError: false,
    }

    const rawPremiumAllocationAmount = calculatePremiumAllocationAmount(
      premiumAllocationPercent,
      sectionFormFields.layerWrittenPremium,
    )

    const rawPremiumAllocationValueAndCurrency = {
      unit: sectionFormFields.premiumAllocationValueAndCurrency.raw.unit,
      amount: rawPremiumAllocationAmount,
    }
    const premiumAllocationValueAndCurrency = {
      raw: rawPremiumAllocationValueAndCurrency,
      validated: parseSectionValueAndCurrency(
        rawPremiumAllocationValueAndCurrency,
      ),
      showError: false,
    }

    return {
      premiumAllocationValueAndCurrency,
      premiumAllocationPercent,
    }
  }

  const handleSectionChange = (
    sectionIndex: number,
    field: keyof SectionFormField,
    value: string | number | boolean | ValueAndCurrency | null,
  ): void => {
    setLayerFormFields(prevState => {
      const prevSectionFormFields =
        prevState[openLayer.layerId].sections[sectionIndex]

      let updatedField
      switch (field) {
        case "premiumAllocationValueAndCurrency":
          updatedField = updatePremiumAllocationValueAndCurrency(
            value as ValueAndCurrency,
            prevSectionFormFields,
          )
          break
        case "premiumAllocationPercent":
          updatedField = updatePremiumAllocationPercent(
            value as number,
            prevSectionFormFields,
          )
          break
        default:
          updatedField = {
            [field]: {
              raw: value,
              validated:
                field === "policyRef"
                  ? parsePolicyReference(value as string)
                  : field === "eea"
                  ? parseEeaOrRow(value as boolean)
                  : value,
              showError: false,
            },
          }
      }

      const updatedSectionFormFields = {
        ...prevSectionFormFields,
        ...updatedField,
      }

      return {
        ...prevState,
        [openLayer.layerId]: {
          ...prevState[openLayer.layerId],
          sections: {
            ...prevState[openLayer.layerId].sections,
            [sectionIndex]: updatedSectionFormFields,
          },
        },
      }
    })
  }

  return (
    <>
      <CollapsibleSectionPanel title="Layer Details">
        <LayerDetailsPanel
          key={openLayer.layerId}
          minorClass={openLayer.minorClass}
          subClass={openLayer.subClass}
          onChangeMinorClass={handleMinorClassChange}
          onChangeSubClass={handleSubClassChange}
          brokerIndustry={openLayer.brokerSelectedIndustry}
          classesRequest={{
            inceptionYear: openLayer.yoa,
            groupClass: openLayer.groupClass,
            producingTeam: openLayer.producingTeam,
            classType: openLayer.classType,
            majorClass:
              MAJOR_CLASS_CODE_DESCRIPTION_MAPPING[openLayer.majorClass],
          }}
          onChangeRenewalField={handleRenewalFieldChange}
          renewalContract={openLayer.renewalContract}
          selectedSubClass={openLayer.selectedSubClass}
          quoteSource={openLayer.quoteSource}
        />
      </CollapsibleSectionPanel>
      {Object.entries(openLayer.sections).map(([sectionIndex, section]) => (
        <CollapsibleSectionPanel key={sectionIndex} title={`Ki Section`}>
          <ContractSectionsPanel
            policyReference={section.policyRef}
            nominatedSyndicateId={section.nominatedSyndicateId}
            onChangePolicyReference={(value: string) => {
              handleSectionChange(Number(sectionIndex), "policyRef", value)
            }}
            eeaOrRow={section.eea}
            onChangeEeaOrRow={(value: boolean | null) => {
              handleSectionChange(Number(sectionIndex), "eea", value)
            }}
            premiumAllocationValueAndCurrency={
              section.premiumAllocationValueAndCurrency
            }
            onChangePremiumAllocationValueAndCurrency={(
              value: ValueAndCurrency,
            ) => {
              handleSectionChange(
                Number(sectionIndex),
                "premiumAllocationValueAndCurrency",
                value,
              )
            }}
            premiumAllocationPercent={section.premiumAllocationPercent}
            onChangePremiumAllocationPercent={(value: number | null) => {
              handleSectionChange(
                Number(sectionIndex),
                "premiumAllocationPercent",
                value,
              )
            }}
          />
        </CollapsibleSectionPanel>
      ))}
    </>
  )
}

export default ContractSectionLayer
