import { FC, PropsWithChildren, ReactNode, Suspense, useId } from "react"

import { Tabs } from "@appia/ui-components"

import Loading from "src/components/Loading"
import ExcelToolbar from "src/components/ExcelViewer/Toolbar"
import PDFViewer from "src/components/PDFViewer"

import type { Document } from "@appia/api"

const TabContentInner: FC<PropsWithChildren> = ({ children }) => (
  <div className="flex w-full grow flex-col rounded-b-md border border-otto-grey-400 bg-otto-grey-300 p-8 pt-0">
    {children}
  </div>
)

export const MessageWrapper: FC<PropsWithChildren> = ({ children }) => (
  <TabContentInner>
    <div className="flex w-full grow items-center justify-center bg-white">
      <div className="p-2 text-center text-lg text-otto-grey-900">
        {children}
      </div>
    </div>
  </TabContentInner>
)

export const DocumentContent: FC<{
  document: Document
  handleDownloadClick?: () => void
}> = ({ document, handleDownloadClick }) => {
  const excelElementId = useId()

  return document.isSpreadsheet ? (
    <>
      <ExcelToolbar
        excelElementId={excelElementId}
        documentName={document.name}
        documentUrl={document.url}
        documentMimeType={document.mimetype}
      />

      <MessageWrapper>
        <div className="text-otto-grey-700">
          <p className="font-bold">
            Otto doesn&rsquo;t show Excel files in the review screen.
          </p>
          <p>You will still be able to submit the file to DMS and TPU.</p>
        </div>
      </MessageWrapper>
    </>
  ) : (
    <PDFViewer
      activeField={null}
      boundingBoxes={[]}
      cardClassName="rounded-t-none"
      documentMimeType={document.mimetype}
      documentName={document.name}
      documentUrl={document.url}
      onDownloadClick={handleDownloadClick}
    />
  )
}

export const DocumentTabs: FC<{
  title: string
  activeTabId: string | null
  setActiveTabId: (id: string) => void
  tabTriggers: { label: string; value: string }[]
  tabPanels: { value: string; content: ReactNode }[]
}> = ({ title, activeTabId, setActiveTabId, tabTriggers, tabPanels }) => (
  <div className="relative">
    <Tabs.Root asChild value={activeTabId || ""} onValueChange={setActiveTabId}>
      <section
        className="absolute flex h-full w-full flex-col"
        aria-label={title}
      >
        <Tabs.List
          className="divide-x divide-otto-grey-300 border border-b-0 border-otto-grey-300"
          aria-label={title}
        >
          {tabTriggers.map(({ label, value }) => (
            <Tabs.Trigger
              key={value}
              value={value}
              className="p-2 text-sm font-medium"
            >
              {label}
            </Tabs.Trigger>
          ))}
        </Tabs.List>

        <Suspense
          fallback={
            <TabContentInner>
              <Loading className="mt-4" />
            </TabContentInner>
          }
        >
          {tabPanels.map(({ content, value }) => (
            <Tabs.Content asChild value={value} key={value}>
              <div className="flex h-full flex-grow flex-col">{content}</div>
            </Tabs.Content>
          ))}
        </Suspense>
      </section>
    </Tabs.Root>
  </div>
)
