import { SUB_CLASS_CODE_DESCRIPTION_MAPPING, SubClassCode } from "@appia/api"
import { prettyPrintSnakeCase } from "src/utils/prettyPrinters"

const getShowTextBasedOnQuoteSource = (
  selectedSubClass: string | undefined,
  brokerIndustry: string | null,
  quoteSource: number | undefined,
): string => {
  const validatedSubclassCode = selectedSubClass
    ? (SubClassCode[
        selectedSubClass as keyof typeof SubClassCode
      ] as SubClassCode)
    : null

  const validatedSelectedSubClass = selectedSubClass
    ? prettyPrintSnakeCase(selectedSubClass)
    : "N/A"

  const brokerSelectedIndustryText = `Broker selected industry: ${
    brokerIndustry ?? "N/A"
  }`
  const kiutSelectedSubclassText = `KiUT selected subclass: ${
    validatedSubclassCode
      ? SUB_CLASS_CODE_DESCRIPTION_MAPPING[validatedSubclassCode]
      : validatedSelectedSubClass
  }`

  return quoteSource === 3
    ? kiutSelectedSubclassText
    : brokerSelectedIndustryText
}

export default getShowTextBasedOnQuoteSource
