import { KiAnswer, KiQuote, PBQABrokerAnswer, PBQASurvey } from "@appia/api"
import { PBQAReviewState } from "src/screens/ReviewPBQA/state"
import {
  scoreExactMatchString,
  scoreFuzzyMatchString,
  scoreRelativeDays,
  scoreRelativeMatchStrings,
  scoreRelativeNumericalValue,
} from "./utils"

export interface QuoteMatch {
  assuredName: string
  broker: string
  countryOfInsured: string
  limit: number
  excess: number
  premium: number
  leaderShare: number
  brokerage: number
  otherDeductions: number
  inceptionDate: Date
  expirationDate: Date
  lloydsCodes: Set<string>
}

const parseDate = (dateString: Date | undefined): Date => {
  return dateString ? new Date(dateString) : new Date()
}

export const getMatchingQuestionIds = (
  activeSurvey: PBQASurvey,
): Record<string, string> => {
  const questionMatchCriteria = {
    assuredName: {
      type: "insured",
      layout: "comparison",
      text: ["Does the (re)insured match the Quote?"],
    },
    broker: {
      type: "broker",
      layout: "comparison",
      text: ["Does the broking house match the Quote?"],
    },
    countryOfInsured: {
      type: "country",
      layout: "comparison",
      text: [
        "Does the domicile in the slip match the domicile selected in the Quote?",
      ],
    },
    limit: {
      type: "decimal",
      layout: "comparison",
      text: ["Does the sum (re)insured or limit match the Quote?"],
    },
    excess: {
      type: "decimal",
      layout: "comparison",
      text: [
        "Do the sections quoted match the sections on the slip?",
        "Does the excess match the Quote?",
      ],
    },
    premium: {
      type: "decimal",
      layout: "comparison",
      text: [
        "For each section, does the premium or rate stated match the Quote?",
      ],
    },
    brokerage: {
      type: "decimal",
      layout: "comparison",
      text: ["Does the brokerage match the Quote?"],
    },
    otherDeductions: {
      type: "decimal",
      layout: "comparison",
      text: ["Do the other deductions match the Quote?"],
    },
    inceptionDate: {
      type: "inception_date",
      layout: "comparison",
      text: ["Does the inception date match the Quote?"],
    },
    expirationDate: {
      type: "date",
      layout: "comparison",
      text: ["Does the expiry date match the Quote?"],
    },
    lloydsCodes: {
      type: "option_multi",
      layout: "comparison",
      text: ["Do the risk codes on the slip match the Quote?"],
    },
    leaderShare: {
      type: "decimal",
      layout: "question",
      text: [
        "Does the leader's line on the slip/e-placing system match the Quote (for each section)?",
        "Does the Nominated Syndicate's line on the slip/e-placing system match the Quote (for each section)?",
      ],
    },
  }

  const matchingQuestionIds: Record<string, string> = {}

  activeSurvey.questions.forEach(q => {
    Object.entries(questionMatchCriteria).forEach(([key, criteria]) => {
      if (
        q.type === criteria.type &&
        q.layout === criteria.layout &&
        (!criteria.text || criteria.text.includes(q.text))
      ) {
        matchingQuestionIds[key] = q.id
      }
    })
  })

  return matchingQuestionIds
}

export const extractQuoteMatchAnswersOrDefault = (
  quote: KiQuote,
  matchingQuestionIds: Record<string, string>,
): QuoteMatch => {
  const extractAnswersFromQuoteData = (
    quoteData: Record<
      string,
      KiAnswer | Partial<Record<string, KiAnswer>> | undefined
    >,
    matchingIds: Record<string, string>,
  ): Partial<QuoteMatch> => {
    const extractedAnswers: Partial<QuoteMatch> = {}
    Object.entries(matchingIds).forEach(([field, questionId]) => {
      const dataValue = quoteData[questionId]
      if (field === "broker") {
        // @ts-expect-error - ignoring type
        extractedAnswers[field] = dataValue?.name ?? ""
      } else {
        // @ts-expect-error - ignoring type
        extractedAnswers[field] = dataValue?.answer ?? dataValue ?? ""
      }
    })

    return extractedAnswers
  }

  const extractedAnswers = extractAnswersFromQuoteData(
    quote.data,
    matchingQuestionIds,
  )

  return {
    assuredName: extractedAnswers.assuredName ?? "",
    broker: extractedAnswers.broker ?? "",
    countryOfInsured: extractedAnswers.countryOfInsured ?? "",
    limit: Number(extractedAnswers.limit) ?? quote.limit ?? 0,
    excess: Number(extractedAnswers.excess) ?? 0,
    premium: Number(extractedAnswers.premium) ?? quote.premium ?? 0,
    leaderShare: Number(extractedAnswers.leaderShare) ?? 0,
    brokerage: Number(extractedAnswers.brokerage) ?? 0,
    otherDeductions: Number(extractedAnswers.otherDeductions) ?? 0,
    inceptionDate: parseDate(extractedAnswers.inceptionDate),
    expirationDate: parseDate(extractedAnswers.expirationDate),
    lloydsCodes: new Set(extractedAnswers.lloydsCodes ?? []),
  }
}

export const extractPbqaStateMatchAnswersOrDefault = (
  state: PBQAReviewState,
  matchingQuestionIds: Record<string, string>,
): QuoteMatch => {
  const extractedAnswers: Partial<QuoteMatch> = {}
  Object.entries(matchingQuestionIds).forEach(([key, questionId]) => {
    const matchingAnswer = Object.values(state).find(
      s => s.question.id === questionId,
    )

    if (matchingAnswer) {
      if (
        key === "broker" &&
        matchingAnswer.answer &&
        isBrokerAnswer(matchingAnswer.answer)
      ) {
        extractedAnswers[key] = matchingAnswer.answer?.answer?.name ?? ""
      } else {
        // @ts-expect-error - ignoring type
        extractedAnswers[key] = matchingAnswer.answer?.answer ?? ""
      }
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isBrokerAnswer(answer: any): answer is PBQABrokerAnswer {
    return (
      answer &&
      typeof answer === "object" &&
      "answer" in answer &&
      typeof answer.answer === "object" &&
      answer.answer !== null &&
      "name" in answer.answer
    )
  }

  return {
    assuredName: extractedAnswers.assuredName ?? "",
    broker: extractedAnswers.broker ?? "",
    countryOfInsured: extractedAnswers.countryOfInsured ?? "",
    limit: Number(extractedAnswers.limit) ?? 0,
    excess: Number(extractedAnswers.excess) ?? 0,
    premium: Number(extractedAnswers.premium) ?? 0,
    leaderShare: Number(extractedAnswers.leaderShare) ?? 0,
    brokerage: Number(extractedAnswers.brokerage) ?? 0,
    otherDeductions: Number(extractedAnswers.otherDeductions) ?? 0,
    inceptionDate: parseDate(extractedAnswers.inceptionDate),
    expirationDate: parseDate(extractedAnswers.expirationDate),
    lloydsCodes: Array.isArray(extractedAnswers.lloydsCodes)
      ? new Set(extractedAnswers.lloydsCodes)
      : new Set<string>(),
  }
}

export const calculateQuoteLineMatchingPercentage = (
  quote: QuoteMatch,
  pbqaState: QuoteMatch,
): number => {
  const MAXIMUM_DAYS_CONSTANT = 28

  const assuredNameScore = scoreFuzzyMatchString(
    quote.assuredName,
    pbqaState.assuredName,
  )
  const brokerScore = scoreFuzzyMatchString(quote.broker, pbqaState.broker)
  const countryOfInsuredScore = scoreExactMatchString(
    quote.countryOfInsured,
    pbqaState.countryOfInsured,
  )
  const limitScore = scoreRelativeNumericalValue(quote.limit, pbqaState.limit)
  const excessScore = scoreRelativeNumericalValue(
    quote.excess,
    pbqaState.excess,
  )
  const premiumScore = scoreRelativeNumericalValue(
    quote.premium,
    pbqaState.premium,
  )
  const leaderShareScore = scoreRelativeNumericalValue(
    quote.leaderShare,
    pbqaState.leaderShare,
  )
  const brokerageScore = scoreRelativeNumericalValue(
    quote.brokerage,
    pbqaState.brokerage,
  )
  const otherDeductionsScore = scoreRelativeNumericalValue(
    quote.otherDeductions,
    pbqaState.otherDeductions,
  )
  const inceptionDateScore = scoreRelativeDays(
    quote.inceptionDate,
    pbqaState.inceptionDate,
    MAXIMUM_DAYS_CONSTANT,
  )
  const expirationDateScore = scoreRelativeDays(
    quote.expirationDate,
    pbqaState.expirationDate,
    MAXIMUM_DAYS_CONSTANT,
  )
  const lloydsCodesScore = scoreRelativeMatchStrings(
    quote.lloydsCodes,
    pbqaState.lloydsCodes,
  )

  // Weights for each factor
  const weights = {
    assuredNameWeight: 2,
    brokerWeight: 1,
    countryOfInsuredWeight: 1,
    limitWeight: 1,
    excessWeight: 1,
    premiumWeight: 1,
    leaderShareWeight: 1,
    brokerageWeight: 1,
    otherDeductionsWeight: 1,
    inceptionDateWeight: 1,
    expirationDateWeight: 1,
    lloydsCodesWeight: 1,
  }

  const totalWeightedSum = Object.values(weights).reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0,
  )

  const totalWeightedScore =
    weights.assuredNameWeight * assuredNameScore +
    weights.brokerWeight * brokerScore +
    weights.countryOfInsuredWeight * countryOfInsuredScore +
    weights.limitWeight * limitScore +
    weights.excessWeight * excessScore +
    weights.premiumWeight * premiumScore +
    weights.leaderShareWeight * leaderShareScore +
    weights.brokerageWeight * brokerageScore +
    weights.otherDeductionsWeight * otherDeductionsScore +
    weights.inceptionDateWeight * inceptionDateScore +
    weights.expirationDateWeight * expirationDateScore +
    weights.lloydsCodesWeight * lloydsCodesScore

  const matchingPercentage = totalWeightedScore / totalWeightedSum

  return Math.round(matchingPercentage * 100) / 100
}

const calculateQuoteLineMatching = (
  activeSurvey: PBQASurvey,
  quote: KiQuote,
  state: PBQAReviewState,
): number => {
  const matchingQuestionIds = getMatchingQuestionIds(activeSurvey)

  const extractedQuoteMatchValues = extractQuoteMatchAnswersOrDefault(
    quote,
    matchingQuestionIds,
  )

  const extractedPbqaStateQuoteMatchValues =
    extractPbqaStateMatchAnswersOrDefault(state, matchingQuestionIds)

  const quoteMatchCalculatePercent = calculateQuoteLineMatchingPercentage(
    extractedQuoteMatchValues,
    extractedPbqaStateQuoteMatchValues,
  )

  return quoteMatchCalculatePercent
}

export default calculateQuoteLineMatching
