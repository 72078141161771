import { ContractSectionItem } from "@appia/api"

// Syndicate information in the order they should be displayed
export const syndicateInfo = [
  { nominatedSyndicateId: 1618, display: "Ki 1618" },
  { nominatedSyndicateId: 4711, display: "Aspen 4711" },
  { nominatedSyndicateId: 5000, display: "Travelers 5000" },
  { nominatedSyndicateId: 2623, display: "Beazley 2623" },
  { nominatedSyndicateId: 5555, display: "QBE 5555" },
]

export const extractSyndicateIds = (
  contractSectionData: ContractSectionItem,
): number[] => {
  return contractSectionData.lines.map(line => line.nominatedSyndicateId)
}

export const orderedSyndicateNames = (
  contractSectionData: ContractSectionItem,
): string => {
  const syndicateIds = extractSyndicateIds(contractSectionData)
  return syndicateInfo
    .filter(info => syndicateIds.includes(info.nominatedSyndicateId))
    .map(info => info.display)
    .join(", ")
}
