import {
  kiCasualtyDivisionOptions,
  kiPropertyDivisionOptions,
  kiSpecialtyDivisionOptions,
} from "@appia/api"

export const groupClassToDivisionMapping: { [key: string]: string[] } = {
  casualty: Object.keys(kiCasualtyDivisionOptions),
  property: Object.keys(kiPropertyDivisionOptions),
  specialty: Object.keys(kiSpecialtyDivisionOptions),
}
